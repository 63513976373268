import React from 'react'

const MyCustomToast = ({ appearance, children }) => (



<div style={{ background: appearance === 'error' ? '#e82646' : '#0aad95',marginBottom:"20px" }} className="toast align-items-center text-white  border-0 show fade" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
<div className="d-flex">
    <div className="toast-body">
    {children}
    </div>
    <button aria-label="Close" className="btn-close fs-20 ms-auto mt-2 pe-2" data-bs-dismiss="toast"><span aria-hidden="true">×</span></button>
</div>
</div>
);

export default MyCustomToast;