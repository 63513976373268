import  {
    LOGIN_REQUEST ,
    LOGIN_SUCCESS ,
    LOGIN_FAIL,
    LOGOUT,  
    CHANGE_PASSWORD_REQUEST ,
    CHANGE_PASSWORD_SUCCESS ,
    CHANGE_PASSWORD_FAIL, 

} from '../constants/loginConstants';
  
  export const loginReducer = (
    state = { loading: false , loadingChangePass: false,userInfos:[]},
    action
  ) => {
    switch (action.type) {

      case LOGIN_REQUEST:
        return { loading: true };
      case LOGIN_SUCCESS:
        return {
          ...state,
          loading: false,
          token:action.payload.token,
          logged: true,
          role:action.payload.role,
          name:action.payload.name
        };
      case LOGIN_FAIL:
        return { userInfos:[],loading: false, error: action.payload };

        //CHANGE PASS
      case CHANGE_PASSWORD_REQUEST:
        return {...state, loadingChangePass: true };
      case CHANGE_PASSWORD_SUCCESS:
        return {
          ...state,
          loadingChangePass: false,
          successChangePass:true
        };
      case CHANGE_PASSWORD_FAIL:
    
        return {...state, loadingChangePass: false, successChangePass:false, error: action.payload };

        //LOGOUT
      case LOGOUT:
        console.log('lougiyt' )
        return { };
      default:
        return state;
    }
  };