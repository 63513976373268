import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../components/footer';
import LeftMenu from '../components/leftMenu';
import TopBar from '../components/topBar';
import PieChart from './PieChart';
import axios from 'axios';
import { APIURL } from '../utils';
import Treemap from './Treemap';
import { getApplications } from '../redux/actions/applicationAction';
import Modal from "react-bootstrap/Modal";
import { Line } from 'react-chartjs-2';
import { Button } from 'react-bootstrap';
import Loading from '../components/loading';
import { getReminiderevents } from '../redux/actions/applicationAction';
import styled from "@emotion/styled";
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip, Filler } from 'chart.js';

import { getDepartements } from '../redux/actions/departementsAction';
import { getChercheurs } from '../redux/actions/chercheursAction';
import Barr from './Barr';
import Barrs from './Barrs';


export const StyleWrapper = styled.div`
  .fc-button.fc-prev-button, .fc-button.fc-next-button, .fc-button.fc-button-primary{
    background: #1086ce;
    background-image: "none";
    border-color :#1086ce;
}
.fc-toolbar-title, {
    color: #1086ce;
    font-weight: 500;
}
.fc-col-header-cell-cushion,.fc-daygrid-day-number {
    color: #1086ce !important;
   
}

`

export default function Home() {

    const { loadingGetRemindeLineEvents, reminderLineEvents } = useSelector((state) => state.applications);
    const { loadingChercheurs, chercheurs } = useSelector((state) => state.chercheurs);
    const { loadingDepartements, departements } = useSelector((state) => state.departements);

    const currenteventclicked = useState({ eventtitle: "", project_title: "", nom: "", prenom: "", dossier: "", departnom: "", typeid: "", ref: "" })

    const { loadingApplications, applications } = useSelector((state) => state.applications);

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false)


    };

    const { token } = useSelector((state) => state.userInfo);

    const dispatch = useDispatch()

    useEffect(() => {
        console.log('dashbord')
        const fetchData = async () => {
            const { data } = await axios.get(`${APIURL}/api/getDossierByDay`, { headers: { "Authorization": `Bearer ${token}` } });
            // console.log(data)
            setChartData({

                labels: data?.data.map((item) => item.date_de_depot),

                datasets: [
                    {
                        label: "Patents",
                        data: data?.data.map((item) => item.count),
                        // fill:true,
                        borderColor: "rgb(254,113,99)",
                        backgroundColor: "rgb(254,113,99)",
                        color: "white",
                        borderWidth: 1
                    }
                ]

            })

        }
        fetchData();
        dispatch(getReminiderevents(token));
        dispatch(getApplications(token));
        dispatch(getChercheurs(token));
        dispatch(getDepartements(token));

    }, [dispatch])


    const [chartData, setChartData] = useState({
        datasets: [],
    });
    const options = {
        responsive: true,
        plugins: {

            legend: true,

            title: { display: true }
        },
        scales: {
            y: {

                beginAtZero: true,
                min: 0

            }
        }
    }
    const { opened } = useSelector((state) => state.appConfig);
    ChartJS.register(
        LineElement,
        CategoryScale,
        LinearScale,
        PointElement,
        Legend,
        Tooltip,
        Filler
    );

    return (

        <div className="horizontalMenucontainer">
            {loadingApplications ? <Loading></Loading> :
                <Modal style={{ borderRadius: '50px' }} show={show} onHide={handleClose}>
                    <Modal.Header closeButton style={{ backgroundImage: 'url(https://img.freepik.com/vecteurs-libre/fond-nuage-ciel-pastel-aquarelle-peint-main_41066-1941.jpg?w=900&t=st=1684320670~exp=1684321270~hmac=5ce3380554c2b8234b3fa749b9b77012932427a81890bfd18337be18c59b2bd4)', textAlign: "center" }}>
                        <Modal.Title > <h1 style={{ color: "#fff", fontSize: 20, fontFamily: 'Calibri', fontWeight: 'bold' }} className="page-title">{currenteventclicked.eventtitle}</h1></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <p style={{ color: "#1086ce", fontSize: 20, fontFamily: 'Calibri', fontWeight: 'bold' }}>The filing number: <h1 className="page-title" style={{ color: "black" }}>{currenteventclicked.ref}</h1> </p>
                        <p style={{ color: "#1086ce", fontSize: 20, fontFamily: 'Calibri', fontWeight: 'bold' }}>The project name :   <h1 style={{ color: "black" }} className="page-title">{currenteventclicked.dossier}</h1></p>
                        <p style={{ color: "#1086ce", fontSize: 20, fontFamily: 'Calibri', fontWeight: 'bold' }}>The inventor's last name :   <h1 style={{ color: "black" }} className="page-title">{currenteventclicked.nom}</h1></p>
                        <p style={{ color: "#1086ce", fontSize: 20, fontFamily: 'Calibri', fontWeight: 'bold' }}>The inventor's first name :  <h1 style={{ color: "black" }} className="page-title">{currenteventclicked.prenom}</h1></p>
                        <p style={{ color: "#1086ce", fontSize: 20, fontFamily: 'Calibri', fontWeight: 'bold' }}>The name of entity :  <h1 style={{ color: "black" }} className="page-title">{currenteventclicked.departnom}</h1></p>

                    </Modal.Body>
                    <Modal.Footer >
                        <Button style={{ backgroundColor: "#fe7163", fontWeight: 'bold', fontFamily: 'Calibri' }} onClick={handleClose}>
                            Close
                        </Button>


                    </Modal.Footer>
                </Modal>
            }
            <div id="global-loader" style={{ display: "none" }}>
                <img src="./assets/images/loader.svg" className="loader-img" alt="Loader" />
            </div>

            <div className="page">
                <div className="page-main"></div>
                <TopBar />
                <LeftMenu />

                <div className="main-content app-content mt-0" style={{ marginLeft: opened ? "270px" : "0px" }}>
                    <div className="side-app">
                        <div className="main-container container-fluid">
                            <div className="page-header" style={{ paddingTop: '70px' }}>
                                <div>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                                    </ol>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                                            <div className="card overflow-hidden">
                                                <div className="card-body" style={{ backgroundImage: 'url(https://img.freepik.com/vecteurs-libre/fond-nuage-ciel-pastel-aquarelle-peint-main_41066-1941.jpg?w=900&t=st=1684320670~exp=1684321270~hmac=5ce3380554c2b8234b3fa749b9b77012932427a81890bfd18337be18c59b2bd4)' }}>
                                                    <div className="d-flex">
                                                        <div style={{ marginLeft: '3px', marginTop: '1px' }}>
                                                            <h6 className="" style={{ color: "#fff", fontSize: 20, fontFamily: 'Calibri', fontWeight: 'bold' }}>Filed patents</h6>
                                                            {loadingApplications ? <Loading></Loading> :
                                                                <h2 className="mb-0 number-font" style={{ color: "#fff", fontSize: 25, fontFamily: 'Calibri', fontWeight: 'bold' }}>{applications?.length}</h2>

                                                            }
                                                        </div>
                                                        <div style={{ marginRight: '-104px', marginTop: '33px', paddingLeft: '106px' }}>
                                                            <h2 ><i className="fa fa-file-text-o text-white"></i></h2>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <div className="chart-wrapper mt-1">
                                                                <canvas id="saleschart"
                                                                    className="h-8 w-9 chart-dropshadow"></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                                            <div className="card overflow-hidden">
                                                <div className="card-body" style={{ backgroundImage: 'url(https://img.freepik.com/vecteurs-libre/fond-nuage-ciel-pastel-aquarelle-peint-main_41066-1941.jpg?w=900&t=st=1684320670~exp=1684321270~hmac=5ce3380554c2b8234b3fa749b9b77012932427a81890bfd18337be18c59b2bd4)' }}>
                                                    <div className="d-flex">
                                                        <div style={{ marginLeft: '3px', marginTop: '1px' }}>
                                                            <h6 className="" style={{ color: "#fff", fontSize: 20, fontFamily: 'Calibri', fontWeight: 'bold' }}>Inventors</h6>
                                                            {loadingChercheurs ? <Loading></Loading> :
                                                                <h2 className="mb-0 number-font" style={{ color: "#fff", fontSize: 25, fontFamily: 'Calibri', fontWeight: 'bold' }} >{chercheurs?.length}</h2>
                                                            }
                                                        </div>
                                                        <div style={{ marginRight: '-104px', marginTop: '33px', paddingLeft: '122px' }}>

                                                            <h2 ><i className="fa fa-graduation-cap text-white"></i></h2>

                                                        </div>
                                                        <div className="ms-auto">
                                                            <div className="chart-wrapper mt-1">
                                                                <canvas id="profitchart"
                                                                    className="h-8 w-9 chart-dropshadow"></canvas>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                                            <div className="card overflow-hidden">
                                                <div className="card-body" style={{ backgroundImage: 'url(https://img.freepik.com/vecteurs-libre/fond-nuage-ciel-pastel-aquarelle-peint-main_41066-1941.jpg?w=900&t=st=1684320670~exp=1684321270~hmac=5ce3380554c2b8234b3fa749b9b77012932427a81890bfd18337be18c59b2bd4)' }}>
                                                    <div className="d-flex">
                                                        <div style={{ marginLeft: '3px', marginTop: '1px' }}>
                                                            <h6 className="" style={{ color: "#fff", fontSize: 20, fontFamily: 'Calibri', fontWeight: 'bold' }}>Entities</h6>
                                                            {loadingDepartements ? <Loading></Loading> :
                                                                <h2 className="mb-0 number-font" style={{ color: "#fff", fontSize: 25, fontFamily: 'Calibri', fontWeight: 'bold' }}>{departements?.length}</h2>
                                                            }
                                                        </div>
                                                        <div style={{ marginRight: '-104px', marginTop: '33px', paddingLeft: '143px' }}>

                                                            <h2 ><i className="fa fa-building-o text-white"></i></h2>

                                                        </div>
                                                        <div className="ms-auto">
                                                            <div className="chart-wrapper mt-1">
                                                                <canvas id="leadschart"
                                                                    className="h-8 w-9 chart-dropshadow"></canvas>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                                            <div className="card overflow-hidden">
                                                <div className="card-body" style={{ backgroundImage: 'url(https://img.freepik.com/vecteurs-libre/fond-nuage-ciel-pastel-aquarelle-peint-main_41066-1941.jpg?w=900&t=st=1684320670~exp=1684321270~hmac=5ce3380554c2b8234b3fa749b9b77012932427a81890bfd18337be18c59b2bd4)' }}>
                                                    <div className="d-flex">
                                                        <div style={{ marginLeft: '3px', marginTop: '1px' }}>
                                                            <h6 className="" style={{ color: "#fff", fontSize: 20, fontFamily: 'Calibri', fontWeight: 'bold', marginLeft: '-10px', marginRight: '-66px' }}>Patents in the PCT phase</h6>
                                                            {loadingGetRemindeLineEvents ? <Loading></Loading> :

                                                                <h2 className="mb-0 number-font" style={{ color: "#fff", fontSize: 25, fontFamily: 'Calibri', fontWeight: 'bold' }}> {reminderLineEvents?.filter(item => item.typeid === 5 && item.done === 1).length}</h2>
                                                            }
                                                        </div>
                                                        <div style={{ marginRight: '-104px', marginTop: '33px', paddingLeft: '68px' }}>

                                                            <h2 ><i className="fa fa-line-chart text-white"></i></h2>

                                                        </div>
                                                        <div className="ms-auto">
                                                            <div className="chart-wrapper mt-1">
                                                                <canvas id="leadschart"
                                                                    className="h-8 w-9 chart-dropshadow"></canvas>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">

                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xl-6">
                                                        <div className="card overflow-hidden" style={{ width: '581px', marginLeft: '-13px' }}>
                                                            <div className="card-body" style={{ width: '700px' }}>
                                                                <h1 style={{ fontSize: '19px', fontFamily: 'unset', color: '#1086ce', marginLeft: '146px', marginTop: '10px' }}>Number of patents per date</h1>
                                                                <div style={{ padding: '20px', width: '80%' }} ><Line
                                                                    data={chartData}
                                                                    options={options}
                                                                ></Line></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                                                        <div className="card overflow-hidden" style={{ width: '581px' }}>
                                                            <div className="card-body" style={{ width: '700px' }}>
                                                                <h1 style={{ fontSize: '19px', fontFamily: 'unset', color: '#1086ce', marginLeft: '146px', marginTop: '10px' }}>Number of patents per status</h1>
                                                                <div style={{ padding: '20px', width: '80%' }} >  <PieChart />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">

                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xl-6">
                                                        <div className="card overflow-hidden" style={{ width: '581px', marginLeft: '-13px' }}>
                                                            <div className="card-body" style={{ width: '700px' }}>
                                                                <h1 style={{ fontSize: '19px', fontFamily: 'unset', color: '#1086ce', marginLeft: '146px', marginTop: '10px' }}>Number of patents per entity</h1>

                                                                <div style={{ padding: '20px', width: '80%' }} >  <Barr />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xl-3">
                                                        <div className="card overflow-hidden" style={{ width: '581px' }}>

                                                            <div className="card-body" style={{ width: '700px' }}>
                                                                <h1 style={{ fontSize: '19px', fontFamily: 'unset', color: '#1086ce', marginLeft: '146px', marginTop: '10px' }}>Number of inventors per entity</h1>

                                                                <div style={{ padding: '20px', width: '80%' }} >  <Barrs />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                                            <div className="card overflow-hidden">
                                                <div className="card-body">
                                                    <h1 style={{ fontSize: '19px', fontFamily: 'unset', color: '#1086ce', marginTop: '10px', textAlign: 'center' }}>
                                                        Number of patents per inventor</h1>
                                                    <Treemap />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </div>
    )
}
