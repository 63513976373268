export const GET_DEPARTEMENTS_REQUEST = 'GET_DEPARTEMENTS_REQUEST';
export const GET_DEPARTEMENTS_SUCCESS = 'GET_DEPARTEMENTS_SUCCESS';
export const GET_DEPARTEMENTS_FAILED = 'GET_DEPARTEMENTS_FAILED';

export const ADD_DEPARTEMENT_REQUEST = 'ADD_DEPARTEMENT_REQUEST';
export const ADD_DEPARTEMENT_SUCCESS = 'ADD_DEPARTEMENT_SUCCESS';
export const ADD_DEPARTEMENT_FAILED = 'ADD_DEPARTEMENT_FAILED';

export const DELETE_DEPARTEMENT_REQUEST = 'DELETE_DEPARTEMENT_REQUEST';
export const DELETE_DEPARTEMENT_SUCCESS = 'DELETE_DEPARTEMENT_SUCCESS';
export const DELETE_DEPARTEMENT_FAILED = 'DELETE_DEPARTEMENT_FAILED';


export const EDIT_DEPARTEMENT_REQUEST = 'EDIT_DEPARTEMENT_REQUEST';
export const EDIT_DEPARTEMENT_SUCCESS = 'EDIT_DEPARTEMENT_SUCCESS';
export const EDIT_DEPARTEMENT_FAILED = 'EDIT_DEPARTEMENT_FAILED';