import  {
    GET_CHERCHEURS_REQUEST,
    GET_CHERCHEURS_SUCCESS,
    GET_CHERCHEURS_FAILED,
   
    ADD_CHERCHEUR_REQUEST,
    ADD_CHERCHEUR_SUCCESS,
    ADD_CHERCHEUR_FAILED,

    DELETE_CHERCHEUR_REQUEST,
    DELETE_CHERCHEUR_SUCCESS,
    DELETE_CHERCHEUR_FAILED,
    
     
    EDIT_CHERCHEUR_REQUEST,
    EDIT_CHERCHEUR_SUCCESS,
    EDIT_CHERCHEUR_FAILED,
} from '../constants/chercheursConstants';
  
  export const chercheurReducer = (
    state = { loadingChercheurs: false,Chercheurs:[]},
    action
  ) => {
    switch (action.type) {

      // ALL Departements
      case GET_CHERCHEURS_REQUEST:
        return { loadingChercheurs: true };

      case GET_CHERCHEURS_SUCCESS:
        return {
          ...state,
          loadingChercheurs: false,
          chercheurs:action.payload
        };

      case GET_CHERCHEURS_FAILED:
        return { loadingChercheurs: false, errorChercheurs: action.payload };
    
        //ADD RDV

      case ADD_CHERCHEUR_REQUEST:
        return { loadingAddChercheur: true ,...state};

      case ADD_CHERCHEUR_SUCCESS:
        return {
          ...state,
          loadingAddChercheur: false,
          chercheurAdded:action.payload,
          chercheurs:state.chercheurs?.concat(action.payload)

     
        };

      case ADD_CHERCHEUR_FAILED:
        return { loadingAddChercheur: false, errorAddChercheur: action.payload };


        case DELETE_CHERCHEUR_REQUEST:
          return { loadingDeleteChercheur: true , ...state};
  
        case DELETE_CHERCHEUR_SUCCESS:
          // console.log(action.payload)
          return {
            ...state,
            loadingDeleteChercheur: false,
            chercheurs:state.chercheurs?.filter((d) => d.id !== action.payload.deletedResearcher.id)

          }
       
          ;
  
      
        case DELETE_CHERCHEUR_FAILED:
          // console.log(action.payload)
          return {...state, loadingDeleteChercheur: false, errorDeleteChercheur: action.payload };



            //EDIT Researcher

      case EDIT_CHERCHEUR_REQUEST:
        return { loadingEditChercheur: true ,...state, chercheurUpdatedSuccess:false};

      case EDIT_CHERCHEUR_SUCCESS:
        return {
          ...state,
          loadingEditChercheur: false,
          chercheurUpdated:action.payload,
          chercheurUpdatedSuccess:true,
          //chercheurs:state.chercheurs?.concat(action.payload)

     
        };

      case EDIT_CHERCHEUR_FAILED:
        return { loadingEditChercheur: false, chercheurUpdatedSuccess:false,errorEditChercheur: action.payload };

      default:
        return state;
    }
  };