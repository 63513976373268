import React, { useEffect, useRef, useState } from 'react'
import Footer from '../components/footer'
import LeftMenu from '../components/leftMenu'
import TopBar from '../components/topBar'
import { useDispatch, useSelector } from 'react-redux';
import { addChercheur, editChercheurAction, getChercheurs, removeChercheurAction } from '../redux/actions/chercheursAction';
import { getDepartements } from '../redux/actions/departementsAction';
import Modal from "react-bootstrap/Modal";
import Loading from "../components/loading";
import { Button } from 'react-bootstrap';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import 'jquery/dist/jquery.min';
import $ from 'jquery';
import { useToasts } from 'react-toast-notifications';


function Chercheurs() {
    const ref = useRef(null);
    const { addToast } = useToasts();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [applicationToDelete, setApplicationToDelete] = useState();
    const { loadingChercheurs, chercheurUpdatedSuccess, chercheurs, chercheurUpdated } = useSelector((state) => state.chercheurs);
    const { departements } = useSelector((state) => state.departements);

    const { token, role } = useSelector((state) => state.userInfo);
    const [newChercheur, setNewChercheur] = useState({ nom: "", prenom: "", email: "", phone_number: "", departement_id: "" });
    const [editChercheur, setEditChercheur] = useState({ nom: "", prenom: "", email: "", phone_number: "", departement_id: "" });
    const [openModal, setOpenModal] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const dispatch = useDispatch()

    useEffect(() => {



        if (chercheurs == undefined || chercheurUpdated) {
            dispatch(getChercheurs(token));
            dispatch(getDepartements(token));
        }

        if (chercheurUpdatedSuccess) {
            addToast("The modification has been successfully completed", { autoDismiss: true, appearance: 'success', placement: "bottom-center" });

        }


        $(document).ready(function () {
            setTimeout(function () {
                $('#basic-datatable').DataTable();
            }, 2000);

        });


    }
        , [dispatch, token, chercheurUpdated, chercheurs])

    const addChercheurHandler = (newChercheur) => {

        dispatch(addChercheur(token, newChercheur));
        // console.log(newChercheur)
        setOpenModal(false);
        addToast("The inventor has been added", { autoDismiss: true, appearance: 'success', placement: "bottom-center" });
    }
    const removeChercheur = (chercheurId) => {
        setShow(false)
        //   alert(chercheurId)
        dispatch(removeChercheurAction(token, chercheurId))

        addToast("The inventor has been removed", { autoDismiss: true, appearance: 'success', placement: "bottom-center" });

    };
    const editChercheurHandler = (editChercheur) => {

        let chercheur = {
            'id': editChercheur.id,
            'nom': editChercheur.nom,
            'prenom': editChercheur.prenom,
            'email': editChercheur.email,
            'phone_number': editChercheur.phone_number,
            'departement_id': editChercheur.departement_id
        }
        dispatch(editChercheurAction(token, chercheur));
        // console.log(chercheur)
        setOpenModalEdit(false);
    }

    const showModal = () => {
        setOpenModal(true);
    };

    const hideModal = () => {
        setOpenModal(false);
    };


    const showModalEdit = (chercheurToEdit) => {

        setOpenModalEdit(true);
        setEditChercheur(chercheurToEdit)
        // console.log(chercheurToEdit)
    };

    const hideModalEdit = () => {
        setOpenModalEdit(false);
    };

    const { opened } = useSelector((state) => state.appConfig);
    const handleClickDelete = (id) => {
        setApplicationToDelete(id)
        setShow(true)
    }

    return (

        <div className="horizontalMenucontainer">
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={(e) => { e.preventDefault(); removeChercheur(applicationToDelete) }} >
                        Delete
                    </Button>

                </Modal.Footer>
            </Modal>
            <div className="page">
                <div className="page-main"></div>
                <TopBar />
                <LeftMenu />

                <div className="main-content app-content mt-0" style={{ marginLeft: opened ? "270px" : "0px" }}>
                    <div className="side-app">


                        <div className="main-container container-fluid">


                            <div className="page-header">

                                <div>
                                    <ol className="breadcrumb" style={{ marginTop: '71px', marginLeft: '29px' }}>
                                        <li className="breadcrumb-item"><a href="http://localhost:3000/">Dashboard</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Inventors</li>
                                    </ol>
                                </div>
                            </div>

                            {loadingChercheurs ? <Loading></Loading> :
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">

                                            <div className="card-header d-flex justify-content-between">
                                                <h3 className="card-title" style={{ color: "#24416d", fontSize: 20, fontFamily: 'Calibri', fontWeight: 'bold' }}>Inventors</h3>


                                                {loadingChercheurs ? <Loading></Loading> :

                                                    <div className="" style={{ float: 'right' }} >
                                                        {role == "admin" ?
                                                            <button className="modal-effect btn btn-secondary-light d-grid mb-3" data-bs-effect="effect-slide-in-right"
                                                                onClick={showModal}
                                                            > <span className="fe fe-plus">
                                                                </span> New inventor</button>
                                                            : <></>
                                                        }
                                                    </div>
                                                }
                                                {role == "admin" ?
                                                    <Modal show={openModal} onHide={hideModal}>
                                                        <Modal.Header>Add an inventor</Modal.Header>
                                                        <Modal.Body>
                                                            <form onSubmit={(e) => { e.preventDefault(); addChercheurHandler(newChercheur) }}>
                                                                <div className="mb-3">
                                                                    <label for="recipient-name" className="col-form-label">Last name :</label>
                                                                    <input type="text" className='form-control'
                                                                        onChange={(e) => { setNewChercheur({ ...newChercheur, "nom": e.target.value }) }}
                                                                        id="nom" required />
                                                                </div>

                                                                <div className="mb-3">
                                                                    <label for="recipient-name" className="col-form-label">First name :</label>
                                                                    <input type="text" className='form-control'
                                                                        onChange={(e) => { setNewChercheur({ ...newChercheur, "prenom": e.target.value }) }}
                                                                        id="prenom" required />
                                                                </div>

                                                                <div className="mb-3">
                                                                    <label for="recipient-name" className="col-form-label">Email :</label>
                                                                    <input type="text" className='form-control'
                                                                        onChange={(e) => { setNewChercheur({ ...newChercheur, "email": e.target.value }) }}
                                                                        id="email" required />
                                                                </div>

                                                                <div className="mb-3">
                                                                    <label for="recipient-name" className="col-form-label">Phone :</label>
                                                                    <input type="text" className='form-control'
                                                                        onChange={(e) => { setNewChercheur({ ...newChercheur, "phone_number": e.target.value }) }}
                                                                        id="phone_number" required />
                                                                </div>

                                                                <div className="mb-3">
                                                                    <label for="recipient-name" className="col-form-label">Entity :</label>
                                                                    <select name="country" className="form-control form-select" data-bs-placeholder="Select"
                                                                        onChange={(e) => { setNewChercheur({ ...newChercheur, "departement_id": e.target.value }) }}
                                                                        required
                                                                    >
                                                                        <option value="" ></option>
                                                                        {departements != undefined && departements.length > 0 ?
                                                                            departements.map((item, index) => {
                                                                                return <option key={item.id} value={item.id}>{item.nom}</option>
                                                                            })
                                                                            : <option value="" >0 Entity found</option>
                                                                        }

                                                                    </select>
                                                                </div>
                                                                {loadingChercheurs ? <Loading></Loading> :
                                                                    <button className="btn btn-primary" >Add</button>
                                                                }: <></>
                                                                <button style={{ float: "right" }} ref={ref} className="btn btn-light" data-bs-dismiss="modal" onClick={hideModal}>Cancel</button>

                                                            </form>
                                                        </Modal.Body>
                                                    </Modal>

                                                    : <></>
                                                }

                                                {role == "admin" ?

                                                    <Modal show={openModalEdit} onHide={hideModalEdit}>
                                                        <Modal.Header>Modification</Modal.Header>
                                                        <Modal.Body>
                                                            <form >
                                                                <div className="mb-3">
                                                                    <label for="recipient-name" className="col-form-label">Last name :</label>
                                                                    <input type="text" className='form-control'
                                                                        onChange={(e) => { setEditChercheur({ ...editChercheur, "nom": e.target.value }) }}
                                                                        id="nom"
                                                                        value={editChercheur.nom}
                                                                        required />
                                                                </div>

                                                                <div className="mb-3">
                                                                    <label for="recipient-name" className="col-form-label">First name :</label>
                                                                    <input type="text" className='form-control'
                                                                        onChange={(e) => { setEditChercheur({ ...editChercheur, "prenom": e.target.value }) }}
                                                                        id="prenom"
                                                                        value={editChercheur.prenom}
                                                                        required />
                                                                </div>

                                                                <div className="mb-3">
                                                                    <label for="recipient-name" className="col-form-label">Email :</label>
                                                                    <input type="text" className='form-control'
                                                                        onChange={(e) => { setEditChercheur({ ...editChercheur, "email": e.target.value }) }}
                                                                        id="email"
                                                                        value={editChercheur.email}
                                                                        required />
                                                                </div>

                                                                <div className="mb-3">
                                                                    <label for="recipient-name" className="col-form-label">Phone :</label>
                                                                    <input type="text" className='form-control'
                                                                        onChange={(e) => { setEditChercheur({ ...editChercheur, "phone_number": e.target.value }) }}
                                                                        id="phone_number"
                                                                        value={editChercheur.phone_number}
                                                                        required />
                                                                </div>

                                                                <div className="mb-3">
                                                                    <label for="recipient-name" className="col-form-label">Entity :</label>
                                                                    <select name="country" className="form-control form-select" data-bs-placeholder="Select"
                                                                        onChange={(e) => { setEditChercheur({ ...editChercheur, "departement_id": e.target.value }) }}

                                                                        required       >
                                                                        <option value="" ></option>
                                                                        {departements != undefined && departements.length > 0 ?
                                                                            departements.map((item, index) => {
                                                                                return <option selected={item.id == editChercheur.departement_id} key={item.id} value={item.id}>{item.nom}</option>
                                                                            })
                                                                            : <option value="" >0 Entity found</option>
                                                                        }

                                                                    </select>
                                                                </div>

                                                                <button onClick={(e) => { e.preventDefault(); editChercheurHandler(editChercheur) }} className="btn btn-primary" >Confirm the modification</button>


                                                                <button style={{ float: "right" }} ref={ref} className="btn btn-light" data-bs-dismiss="modal" onClick={hideModalEdit}>Cancel</button>

                                                            </form>
                                                        </Modal.Body>
                                                    </Modal>
                                                    : <></>
                                                }
                                            </div>
                                            <div className="card-body">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered " id="basic-datatable" style={{ borderRadius: '12px' }}>
                                                        <thead>
                                                            <tr className='ol'>
                                                                <th style={{ width: '17.2px' }}>ID</th>
                                                                <th style={{ width: '187.562px' }}>Last and first name</th>
                                                                <th >Email</th>
                                                                <th >Phone</th>
                                                                <th style={{ width: '54.58px' }}>Entity</th>
                                                                {role == "admin" ?
                                                                    <th name="bstable-actions" style={{ width: '110px' }}>Actions</th>
                                                                    : <></>
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {chercheurs != undefined && chercheurs.length > 0 ?
                                                                chercheurs.map((item, index) => {
                                                                    return <tr key={item.id}>

                                                                        <td>{index + 1}</td>
                                                                        <td>{item.nom} {item.prenom}</td>
                                                                        <td>{item.email}</td>
                                                                        <td>{item.phone_number}</td>
                                                                        <td>{item.departement.nom}</td>
                                                                        {role == "admin" ?
                                                                            <td name="bstable-actions"><div className="btn-list">
                                                                                <button id="bEdit" onClick={(e) => { e.preventDefault(); showModalEdit(item) }} type="button" className="btn btn-sm btn-primary">
                                                                                    <span className="fe fe-edit"> </span>
                                                                                </button>

                                                                                <button style={{ background: "red" }} id="bEdit" onClick={(e) => { e.preventDefault(); handleClickDelete(item.id) }} type="button" className="btn btn-sm ">
                                                                                    <span><i style={{ color: "white" }} className="fa fa-trash"></i> </span>
                                                                                </button>

                                                                            </div>

                                                                            </td>
                                                                            : <></>
                                                                        }
                                                                    </tr>

                                                                })
                                                                : "0 researchers found"
                                                            }


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }


                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}


export default Chercheurs