

import {
    CHANGELIGHTMODE,
    CHANGESIDEBARE,
} from '../constants/appConfigContants';

export const changeLightMode = () => async (dispatch) => {
    dispatch({
        type: CHANGELIGHTMODE,
    });
};

export const changeSideBare = () => async (dispatch) => {
    dispatch({
        type: CHANGESIDEBARE,
    });
};




