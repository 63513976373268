import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../components/footer';
import LeftMenu from '../components/leftMenu';
import TopBar from '../components/topBar';
import Modal from "react-bootstrap/Modal";
import { Button } from 'react-bootstrap';
import Loading from '../components/loading';
import { getReminiderevents } from '../redux/actions/applicationAction';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import styled from "@emotion/styled";
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip, Filler } from 'chart.js';

import { Link } from 'react-router-dom/cjs/react-router-dom.min';


export const StyleWrapper = styled.div`
  .fc-button.fc-prev-button, .fc-button.fc-next-button, .fc-button.fc-button-primary{
    background: #1086ce;
    background-image: "none";
    border-color :#1086ce;
}
.fc-toolbar-title, {
    color: #1086ce;
    font-weight: 500;
}
.fc-col-header-cell-cushion,.fc-daygrid-day-number {
    color: #1086ce !important;
   
}

`

export default function Agenda() {

    const { reminderLineEvents, application } = useSelector((state) => state.applications);

    const [currenteventclicked, setCurrenteventclicked] = useState({ eventtitle: "", project_title: "", nom: "", prenom: "", dossier: "", departnom: "", typeid: "", ref: "", dossier_id: "" })

    const { loadingApplications } = useSelector((state) => state.applications);


    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false)


    };

    const { token } = useSelector((state) => state.userInfo);

    const dispatch = useDispatch()

    useEffect(() => {
        if (reminderLineEvents === undefined) {
            dispatch(getReminiderevents(token));
        }

    }, [dispatch, token, reminderLineEvents, application])


    const { opened } = useSelector((state) => state.appConfig);
    ChartJS.register(
        LineElement,
        CategoryScale,
        LinearScale,
        PointElement,
        Legend,
        Tooltip,
        Filler
    );

    return (


        <div className="horizontalMenucontainer">
            {loadingApplications ? <Loading></Loading> :
                <Modal style={{ borderRadius: '50px' }} show={show} onHide={handleClose}>
                    <Modal.Header closeButton style={{ backgroundImage: 'url(https://img.freepik.com/vecteurs-libre/fond-nuage-ciel-pastel-aquarelle-peint-main_41066-1941.jpg?w=900&t=st=1684320670~exp=1684321270~hmac=5ce3380554c2b8234b3fa749b9b77012932427a81890bfd18337be18c59b2bd4)', textAlign: "center" }}>
                        <Modal.Title > <h1 style={{ color: "#fff", fontSize: 20, fontFamily: 'Calibri', fontWeight: 'bold' }} className="page-title">{currenteventclicked.eventtitle}</h1></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <p style={{ color: "#1086ce", fontSize: 20, fontFamily: 'Calibri', fontWeight: 'bold' }}>The filing number: <span className="page-title" style={{ color: "black" }}>{currenteventclicked.ref}</span> </p>
                        <p style={{ color: "#1086ce", fontSize: 20, fontFamily: 'Calibri', fontWeight: 'bold' }}>The project name :   <span style={{ color: "black" }} className="page-title">{currenteventclicked.dossier}</span></p>
                        <p style={{ color: "#1086ce", fontSize: 20, fontFamily: 'Calibri', fontWeight: 'bold' }}>The inventor's last name :   <span style={{ color: "black" }} className="page-title">{currenteventclicked.nom}</span></p>
                        <p style={{ color: "#1086ce", fontSize: 20, fontFamily: 'Calibri', fontWeight: 'bold' }}>The inventor's first name :  <span style={{ color: "black" }} className="page-title">{currenteventclicked.prenom}</span></p>
                        <p style={{ color: "#1086ce", fontSize: 20, fontFamily: 'Calibri', fontWeight: 'bold' }}>The name of entity :  <span style={{ color: "black" }} className="page-title">{currenteventclicked.departnom}</span></p>

                    </Modal.Body>
                    <Modal.Footer >
                        <Button style={{ fontWeight: 'bold', fontFamily: 'Calibri' }} onClick={handleClose}>
                            Close
                        </Button>
                        <Link to={"/patentDetails/" + currenteventclicked.dossier_id}>
                            <Button style={{ backgroundColor: "#fe7163", fontWeight: 'bold', fontFamily: 'Calibri' }}>
                                Show more
                            </Button>
                        </Link>


                    </Modal.Footer>
                </Modal>
            }
            <div id="global-loader" style={{ display: "none" }}>
                <img src="./assets/images/loader.svg" className="loader-img" alt="Loader" />
            </div>

            <div className="page">
                <div className="page-main"></div>
                <TopBar />
                <LeftMenu />

                <div className="main-content app-content mt-0" style={{ marginLeft: opened ? "270px" : "0px" }}>
                    <div className="side-app">


                        <div className="main-container container-fluid">


                            <div className="page-header" style={{ paddingTop: '70px' }}>

                                <div>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Agenda</li>
                                    </ol>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                                            <div className="card overflow-hidden">
                                                <div className="card-body">

                                                    <StyleWrapper>
                                                        <FullCalendar
                                                            plugins={[dayGridPlugin]}
                                                            initialView="dayGridMonth"
                                                            events={reminderLineEvents}
                                                            height={"90vh"}
                                                            eventClick={
                                                                function (arg) {

                                                                    setCurrenteventclicked({ eventtitle: arg.event.title, nom: arg.event.extendedProps.nom, prenom: arg.event.extendedProps.prenom, dossier: arg.event.extendedProps.dossier, departnom: arg.event.extendedProps.departnom, ref: arg.event.extendedProps.ref, dossier_id: arg.event.extendedProps.dossier_id })

                                                                    setShow(true)
                                                                }
                                                            }
                                                        />
                                                    </StyleWrapper>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <Footer />

            </div>
        </div>
    )
}
