import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../redux/actions/loginAction'

export default function Login(props) {

    const [loginEmail, setLoginEmail] = useState("")
    const [loginPassword, setLoginPassword] = useState("")
    const { logged, loading, error } = useSelector((state) => state.userInfo);

    const dispatch = useDispatch()


    useEffect(() => {

        if (logged == true) {
            props.history.push('/');
        }
    }, [dispatch, logged])
    //Login
    const submitLoginForm = (event, email, password) => {

        event.preventDefault();
        dispatch(login(email, password))
    }
    return (

        <div className="login-img">
            <div className="page">
                <h2 style={{ color: 'white', textAlign: 'center', fontFamily: "inherit", fontWeight: 'bold', textShadow: '2px 2px 8px #24416d', fontSize: '37px' }}>TLO IP portfolio management</h2>
                <div className="">
                    <div className="col col-login mx-auto mt-7">
                        <div className="text-center">
                            <img src="./assets/images/brand/logo-white.png" className="header-brand-img" alt="" style={{ maxWidth: "255px" }} />
                        </div>
                    </div>

                    <div className="container-login100">
                        <div className="wrap-login100 p-6">
                            <form className="login100-form validate-form">
                                <span className="login100-form-title pb-5">
                                    <b>Login</b>

                                </span>
                                <div className="panel panel-primary">

                                    <div className="panel-body tabs-menu-body p-0 pt-5">
                                        <div className="tab-content">
                                            <div className="tab-pane active" id="tab5">
                                                <div className="wrap-input100 validate-input input-group" data-bs-validate="Valid email is required: ex@abc.xyz">
                                                    <a className="input-group-text bg-white text-muted">
                                                        <i className="zmdi zmdi-email text-muted" aria-hidden="true"></i>
                                                    </a>
                                                    <input className="input100 border-start-0 form-control ms-0" type="email" placeholder="Email"
                                                        required
                                                        onChange={(e) => setLoginEmail(e.target.value)} />
                                                </div>
                                                <div className="wrap-input100 validate-input input-group" id="Password-toggle">
                                                    <a className="input-group-text bg-white text-muted">
                                                        <i className="zmdi zmdi-eye text-muted" aria-hidden="true"></i>
                                                    </a>
                                                    <input className="input100 border-start-0 form-control ms-0" type="password" placeholder="Password"
                                                        required
                                                        onChange={(e) => setLoginPassword(e.target.value)} />
                                                </div>

                                                <div className="container-login100-form-btn">

                                                    <button disabled={loginEmail.length == 0 || loginPassword.length == 0 ? "true" : ""}
                                                        onClick={(e) => submitLoginForm(e, loginEmail, loginPassword)}
                                                        style={{ width: "100%" }}
                                                        className={loginEmail.length == 0 && loginPassword.length == 0 ? "btn btn-primary disabled" : "btn btn-primary"}>
                                                        {loading ? <><span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>

                                                            Loading.. </> : "Log in"}



                                                    </button>

                                                </div>

                                                <div className="container-login100-form-btn">
                                                    {error ? <div style={{ color: "red" }}>Email or password is invalid</div> : <></>}
                                                </div>




                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    )
}
