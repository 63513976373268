export const GET_APPLICATIONS_REQUEST = 'GET_APPLICATIONS_REQUEST';
export const GET_APPLICATIONS_SUCCESS = 'GET_APPLICATIONS_SUCCESS';
export const GET_APPLICATIONS_FAILED = 'GET_APPLICATIONS_FAILED';

export const ADD_APPLICATION_REQUEST = 'ADD_APPLICATION_REQUEST';
export const ADD_APPLICATION_SUCCESS = 'ADD_APPLICATION_SUCCESS';
export const ADD_APPLICATION_FAILED = 'ADD_APPLICATION_FAILED';


export const UPDATE_APPLICATION_REQUEST = 'UPDATE_APPLICATION_REQUEST';
export const UPDATE_APPLICATION_SUCCESS = 'UPDATE_APPLICATION_SUCCESS';
export const UPDATE_APPLICATION_FAILED = 'UPDATE_APPLICATION_FAILED';


export const ADD_FILE_REQUEST = 'ADD_FILE_REQUEST';
export const ADD_FILE_SUCCESS = 'ADD_FILE_SUCCESS';
export const ADD_FILE_FAILED = 'ADD_FILE_FAILED';
export const ALL_FILES_ADDED = 'ALL_FILES_ADDED';



export const DELETE_DOSSIER_REQUEST = 'DELETE_DOSSIER_REQUEST';
export const DELETE_DOSSIER_SUCCESS = 'DELETE_DOSSIER_SUCCESS';
export const DELETE_DOSSIER_FAILED = 'DELETE_DOSSIER_FAILED';


export const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAILED = 'DELETE_FILE_FAILED';

export const DOWNLOAD_FILE_REQUEST = 'DOWNLOAD_FILE_REQUEST';
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_FAILED = 'DOWNLOAD_FILE_FAILED';

export const GET_APPLICATION_REQUEST = 'GET_APPLICATION_REQUEST';
export const GET_APPLICATION_SUCCESS = 'GET_APPLICATION_SUCCESS';
export const GET_APPLICATION_FAILED = 'GET_APPLICATION_FAILED';


export const GET_REMINDERLINE_REQUEST = 'GET_REMINDERLINE_REQUEST';
export const GET_REMINDERLINE_SUCCESS = 'GET_REMINDERLINE_SUCCESS';
export const GET_REMINDERLINE_FAILED = 'GET_REMINDERLINE_FAILED';

export const GET_REMINDERLINEEVENTS_REQUEST = 'GET_REMINDERLINEEVENTS_REQUEST';
export const GET_REMINDERLINEEVENTS_SUCCESS = 'GET_REMINDERLINEEVENTS_SUCCESS';
export const GET_REMINDERLINEEVENTS_FAILED = 'GET_REMINDERLINEEVENTS_FAILED';


export const SWITCH_REMINDERLINEEVENTS_REQUEST = 'SWITCH_REMINDERLINEEVENTS_REQUEST';
export const SWITCH_REMINDERLINEEVENTS_SUCCESS = 'SWITCH_REMINDERLINEEVENTS_SUCCESS';
export const SWITCH_REMINDERLINEEVENTS_FAILED = 'SWITCH_REMINDERLINEEVENTS_FAILED';

export const ADDCOMMENT_REMINDERLINE_REQUEST = 'ADDCOMMENT_REMINDERLINE_REQUEST';
export const ADDCOMMENT_REMINDERLINE_SUCCESS = 'ADDCOMMENT_REMINDERLINE_SUCCESS';
export const ADDCOMMENT_REMINDERLINE_FAILED = 'ADDCOMMENT_REMINDERLINE_FAILED';



