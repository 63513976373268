export const GET_CHERCHEURS_REQUEST = 'GET_CHERCHEURS_REQUEST';
export const GET_CHERCHEURS_SUCCESS = 'GET_CHERCHEURS_SUCCESS';
export const GET_CHERCHEURS_FAILED = 'GET_CHERCHEURS_FAILED';

export const ADD_CHERCHEUR_REQUEST = 'ADD_CHERCHEUR_REQUEST';
export const ADD_CHERCHEUR_SUCCESS = 'ADD_CHERCHEUR_SUCCESS';
export const ADD_CHERCHEUR_FAILED = 'ADD_CHERCHEUR_FAILED';

export const EDIT_CHERCHEUR_REQUEST = 'EDIT_CHERCHEUR_REQUEST';
export const EDIT_CHERCHEUR_SUCCESS = 'EDIT_CHERCHEUR_SUCCESS';
export const EDIT_CHERCHEUR_FAILED = 'EDIT_CHERCHEUR_FAILED';

export const DELETE_CHERCHEUR_REQUEST = 'DELETE_CHERCHEUR_REQUEST';
export const DELETE_CHERCHEUR_SUCCESS = 'DELETE_CHERCHEUR_SUCCESS';
export const DELETE_CHERCHEUR_FAILED = 'DELETE_CHERCHEUR_FAILED';