import Axios from 'axios';
import { APIURL } from '../../utils'


import {
  GET_APPLICATIONS_REQUEST,
  GET_APPLICATIONS_SUCCESS,
  GET_APPLICATIONS_FAILED,

  ADD_APPLICATION_REQUEST,
  ADD_APPLICATION_SUCCESS,
  ADD_APPLICATION_FAILED,


  UPDATE_APPLICATION_REQUEST,
  UPDATE_APPLICATION_SUCCESS,
  UPDATE_APPLICATION_FAILED,


  GET_APPLICATION_REQUEST,
  GET_APPLICATION_SUCCESS,
  GET_APPLICATION_FAILED,

ADD_FILE_REQUEST,
ADD_FILE_SUCCESS,
ADD_FILE_FAILED,


DELETE_FILE_REQUEST,
DELETE_FILE_SUCCESS,
DELETE_FILE_FAILED,

DELETE_DOSSIER_REQUEST,
DELETE_DOSSIER_SUCCESS,
DELETE_DOSSIER_FAILED,


DOWNLOAD_FILE_REQUEST,
DOWNLOAD_FILE_SUCCESS,
DOWNLOAD_FILE_FAILED,

ALL_FILES_ADDED,
GET_REMINDERLINE_REQUEST,
GET_REMINDERLINE_SUCCESS,
GET_REMINDERLINE_FAILED,

GET_REMINDERLINEEVENTS_REQUEST,
GET_REMINDERLINEEVENTS_SUCCESS,
GET_REMINDERLINEEVENTS_FAILED,

SWITCH_REMINDERLINEEVENTS_REQUEST,
SWITCH_REMINDERLINEEVENTS_SUCCESS,
SWITCH_REMINDERLINEEVENTS_FAILED,

ADDCOMMENT_REMINDERLINE_REQUEST,
ADDCOMMENT_REMINDERLINE_SUCCESS,
ADDCOMMENT_REMINDERLINE_FAILED,

} from '../constants/applicationConstants';

export const getApplications = (token) => async (dispatch) => {
  dispatch({
    type: GET_APPLICATIONS_REQUEST,
  });
  try {

    const { data } = await Axios.get(`${APIURL}/api/dossiers`, { headers: { "Authorization": `Bearer ${token}` } });
    // console.log(data)
    dispatch({ type: GET_APPLICATIONS_SUCCESS, payload: data });


  } catch (error) {

    dispatch({ type: GET_APPLICATIONS_FAILED, payload: error });

  }
};



export const addApplication = (token, application) => async (dispatch) => {
  dispatch({
    type: ADD_APPLICATION_REQUEST,
  });
  try {

    const { data } = await Axios.post(`${APIURL}/api/dossiers`, application, { headers: { "Authorization": `Bearer ${token}` } });

    dispatch({ type: ADD_APPLICATION_SUCCESS, payload: data });

  } catch (error) {
    // console.log(application)
     dispatch({ type: ADD_APPLICATION_FAILED, payload:error });

  }
};


export const getApplication = (token,applicationId) => async (dispatch) => {
  
  dispatch({
    type: GET_APPLICATION_REQUEST,
  });
  try {

    const { data } = await Axios.get(`${APIURL}/api/dossiers/${applicationId}`, { headers: { "Authorization": `Bearer ${token}` } });
    // console.log(data)
    dispatch({ type: GET_APPLICATION_SUCCESS, payload: data });


  } catch (error) {
    console.log(error)

    dispatch({ type: GET_APPLICATION_FAILED, payload: error.response.data });

  }
};


export const updateApplicationAction = (token, application) => async (dispatch) => {
  dispatch({
    type: UPDATE_APPLICATION_REQUEST,
  });
  try {

    const { data } = await Axios.put(`${APIURL}/api/dossiers/${application.id}`, application, { headers: { "Authorization": `Bearer ${token}` } });

    dispatch({ type: UPDATE_APPLICATION_SUCCESS, payload: data });

  } catch (error) {
  
    dispatch({ type: UPDATE_APPLICATION_FAILED, payload:error });

  }
};



export const getReminiderLine = (token,reminderLineId) => async (dispatch) => {
  
  dispatch({
    type: GET_REMINDERLINE_REQUEST,
  });
  try {

    const { data } = await Axios.get(`${APIURL}/api/reminder_lines/${reminderLineId}`, { headers: { "Authorization": `Bearer ${token}` } });
    dispatch({ type: GET_REMINDERLINE_SUCCESS, payload: data });


  } catch (error) {

    dispatch({ type: GET_REMINDERLINE_FAILED, payload: error });

  }
};

export const addCommentReminiderLine = (token,reminderLineId,comment) => async (dispatch) => {
  
  dispatch({
    type: ADDCOMMENT_REMINDERLINE_REQUEST,
  });
  try {

    const { data } = await Axios.put(`${APIURL}/api/updateremindercomment/${reminderLineId}`,{"comment":comment}, { headers: { "Authorization": `Bearer ${token}` } });
    dispatch({ type: ADDCOMMENT_REMINDERLINE_SUCCESS, payload: data });


  } catch (error) {

    dispatch({ type: ADDCOMMENT_REMINDERLINE_FAILED, payload: error });

  }
};


export  const  addFile = (token, data2) => async (dispatch) => {

  
  dispatch({
    type: ADD_FILE_REQUEST,
  });
  try {

    const { data } = await Axios.post(`${APIURL}/api/files`,data2, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } });
    dispatch({ type: ADD_FILE_SUCCESS, payload: data });

  } catch (error) {
    dispatch({ type: ADD_FILE_FAILED, payload:error });

  }
};


export  const  removeFileAction = (token, fileId) => async (dispatch) => {

  
  dispatch({
    type: DELETE_FILE_REQUEST,
  });
  try {

    const { data } = await Axios.delete(`${APIURL}/api/files/${fileId}`, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } });
    dispatch({ type: DELETE_FILE_SUCCESS, payload: data });

  } catch (error) {
    dispatch({ type: DELETE_FILE_FAILED, payload:error });

  }
};


export  const  removeDossierAction = (token, dossierId) => async (dispatch) => {

  
  dispatch({
    type: DELETE_DOSSIER_REQUEST,
  });
  try {

    const { data } = await Axios.delete(`${APIURL}/api/delete/${dossierId}`, 
    { headers: { "Authorization": `Bearer ${token}`, 
    'Content-Type': 'multipart/form-data' } });
    dispatch({ type: DELETE_DOSSIER_SUCCESS, payload: data });

  } catch (error) {
    dispatch({ type: DELETE_DOSSIER_FAILED, payload:error });

  }
};


export  const  downloadFileAction = (token, fileId) => async (dispatch) => {

  
  dispatch({
    type: DOWNLOAD_FILE_REQUEST,
  });
  try {

    const { data } = await Axios.get(`${APIURL}/api/downloadFile/${fileId}`, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } });
 
    dispatch({ type: DOWNLOAD_FILE_SUCCESS, payload: data });

  } catch (error) {
    dispatch({ type: DOWNLOAD_FILE_FAILED, payload:error });

  }
};


export  const  fileAdded = () => async (dispatch) => {
  
  dispatch({
    type: ALL_FILES_ADDED,
  });

};


export const getReminiderevents = (token) => async (dispatch) => {
  
  dispatch({
    type: GET_REMINDERLINEEVENTS_REQUEST,
  });
  try {

    const { data } = await Axios.get(`${APIURL}/api/reminderlinesevents/`, { headers: { "Authorization": `Bearer ${token}` } });
    dispatch({ type: GET_REMINDERLINEEVENTS_SUCCESS, payload: data });


  } catch (error) {

    dispatch({ type: GET_REMINDERLINEEVENTS_FAILED, payload: error });

  }
  
};



export const switchRminderLines = (token,current_reminder_line_id,next_reminder_line_id) => async (dispatch) => {
  
  dispatch({
    type: SWITCH_REMINDERLINEEVENTS_REQUEST,
  });
  try {

    const { data } = await Axios.post(`${APIURL}/api/switchPhases/`,{"current_reminder_line_id":current_reminder_line_id,"next_reminder_line_id":next_reminder_line_id}, { headers: { "Authorization": `Bearer ${token}` } });
    dispatch({ type: SWITCH_REMINDERLINEEVENTS_SUCCESS, payload: data });


  } catch (error) {

    dispatch({ type: SWITCH_REMINDERLINEEVENTS_FAILED, payload: error });

  }


  
};
export const switchRminderLines1 = (token,current_reminder_line_id,next_reminder_line_id) => async (dispatch) => {
  
  dispatch({
    type: SWITCH_REMINDERLINEEVENTS_REQUEST,
  });
  try {

    const { data } = await Axios.post(`${APIURL}/api/switchPhases1/`,{"current_reminder_line_id":current_reminder_line_id,"next_reminder_line_id":next_reminder_line_id}, { headers: { "Authorization": `Bearer ${token}` } });
    dispatch({ type: SWITCH_REMINDERLINEEVENTS_SUCCESS, payload: data });


  } catch (error) {

    dispatch({ type: SWITCH_REMINDERLINEEVENTS_FAILED, payload: error });

  }


  
};
export const switchRminderLines2 = (token,current_reminder_line_id,next_reminder_line_id) => async (dispatch) => {
  
  dispatch({
    type: SWITCH_REMINDERLINEEVENTS_REQUEST,
  });
  try {

    const { data } = await Axios.post(`${APIURL}/api/switchPhases2/`,{"current_reminder_line_id":current_reminder_line_id,"next_reminder_line_id":next_reminder_line_id}, { headers: { "Authorization": `Bearer ${token}` } });
    dispatch({ type: SWITCH_REMINDERLINEEVENTS_SUCCESS, payload: data });


  } catch (error) {

    dispatch({ type: SWITCH_REMINDERLINEEVENTS_FAILED, payload: error });

  }


  
};
export const switchRminderLines0 = (token,current_reminder_line_id,next_reminder_line_id) => async (dispatch) => {
  
  dispatch({
    type: SWITCH_REMINDERLINEEVENTS_REQUEST,
  });
  try {

    const { data } = await Axios.post(`${APIURL}/api/switchPhases11/`,{"current_reminder_line_id":current_reminder_line_id,"next_reminder_line_id":next_reminder_line_id}, { headers: { "Authorization": `Bearer ${token}` } });
    dispatch({ type: SWITCH_REMINDERLINEEVENTS_SUCCESS, payload: data });


  } catch (error) {

    dispatch({ type: SWITCH_REMINDERLINEEVENTS_FAILED, payload: error });

  }


  
};
export const switchRminderLines12 = (token,current_reminder_line_id,next_reminder_line_id) => async (dispatch) => {
  
  dispatch({
    type: SWITCH_REMINDERLINEEVENTS_REQUEST,
  });
  try {

    const { data } = await Axios.post(`${APIURL}/api/switchPhases12/`,{"current_reminder_line_id":current_reminder_line_id,"next_reminder_line_id":next_reminder_line_id}, { headers: { "Authorization": `Bearer ${token}` } });
    dispatch({ type: SWITCH_REMINDERLINEEVENTS_SUCCESS, payload: data });


  } catch (error) {

    dispatch({ type: SWITCH_REMINDERLINEEVENTS_FAILED, payload: error });

  }


  
};
export const switchRminderLines3 = (token,current_reminder_line_id,next_reminder_line_id) => async (dispatch) => {
  
  dispatch({
    type: SWITCH_REMINDERLINEEVENTS_REQUEST,
  });
  try {

    const { data } = await Axios.post(`${APIURL}/api/switchPhases3/`,{"current_reminder_line_id":current_reminder_line_id,"next_reminder_line_id":next_reminder_line_id}, { headers: { "Authorization": `Bearer ${token}` } });
    dispatch({ type: SWITCH_REMINDERLINEEVENTS_SUCCESS, payload: data });


  } catch (error) {

    dispatch({ type: SWITCH_REMINDERLINEEVENTS_FAILED, payload: error });

  }


  
};