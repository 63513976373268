import Axios from 'axios';
import {APIURL} from '../../utils'

import {
    LOGIN_REQUEST ,
    LOGIN_SUCCESS ,
    LOGIN_FAIL, 
    CHANGE_PASSWORD_REQUEST ,
    CHANGE_PASSWORD_SUCCESS ,
    CHANGE_PASSWORD_FAIL, 
    LOGOUT
} from '../constants/loginConstants';

export const login = (email,password) => async (dispatch) => {
      dispatch({
        type: LOGIN_REQUEST,
      });
      try {
        const bodyParameters = {
            email:email,
            password:password,
         };
      
        const {data} = await Axios.post(`${APIURL}/api/login`,bodyParameters);
        dispatch({ type: LOGIN_SUCCESS, payload: {token:data.token,user:data.user,logged:true,role:data.role,name:data.name} });
        localStorage.setItem(
             'userData',
             JSON.stringify({token:data.token,user:data.user,logged:true,role:data.role,name:data.name})
          );

      } catch (error) {
       
        dispatch({ type: LOGIN_FAIL, payload: "l'email ou le password est invalide" });
 
      }
    };

export const logout = () => async (dispatch) => {
    localStorage.removeItem('userData');
      dispatch({
        type: LOGOUT,payload:null
      });
      
      document.location.href='/login'
   
    };

export const changePassword = (lastPass,NewPass,userEmail) => async (dispatch) => {
 
  dispatch({
    type: CHANGE_PASSWORD_REQUEST,
  });
  try {
    const bodyParameters={
      identifier :userEmail,
      password: lastPass,
      newPassword: NewPass
    }
  
    const {data} = await Axios.post(`${APIURL}/password`,bodyParameters);
  

    dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: data });

  } catch (error) {
    dispatch({ type: CHANGE_PASSWORD_FAIL, payload: error.message });

  }

   
    };