import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { loginReducer } from './redux/reducers/loginReducer';
import { departementReducer } from './redux/reducers/departementReducer';
import { chercheurReducer } from './redux/reducers/chercheurReducer';
import { applicationReducer} from './redux/reducers/applicationReducer';
import { statusReducer} from './redux/reducers/statusReducer';
import { appConfigReducer } from './redux/reducers/appConfigReducer';

const initialState = {
  userInfo:localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')):{token:"",userinfos:[],logged:false,role:""},

};
const reducer = combineReducers({
userInfo : loginReducer,
departements : departementReducer,
chercheurs : chercheurReducer,
applications : applicationReducer,
statutes : statusReducer,
appConfig : appConfigReducer,

});
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
