import Axios from 'axios';
import {APIURL} from '../../utils'


import {
    GET_STATUTES_REQUEST,
    GET_STATUTES_SUCCESS,
    GET_STATUTES_FAILED,

    ADD_STATUS_REQUEST,
    ADD_STATUS_SUCCESS,
    ADD_STATUS_FAILED,
} from '../constants/statusConstants';

export const getStatutes = (token) => async (dispatch) => {
      dispatch({
        type: GET_STATUTES_REQUEST,
      });
      try {
        
        const {data} = await Axios.get(`${APIURL}/api/statues`,{ headers: {"Authorization" : `Bearer ${token}`} });
        // console.log(data)
        dispatch({ type: GET_STATUTES_SUCCESS, payload: data });

    
      } catch (error) {
       
        dispatch({ type: GET_STATUTES_FAILED, payload:error });

      }
    };



export const addStatus= (token,name,description) => async (dispatch) => {
      dispatch({
        type: ADD_STATUS_REQUEST,
      });
      try {
        
        const {data} = await Axios.post(`${APIURL}/api/statues`,{name:name,description:description} ,{ headers: {"Authorization" : `Bearer ${token}`}});
        
        dispatch({ type: ADD_STATUS_SUCCESS, payload: data });
        
      } catch (error) {
       
        dispatch({ type: ADD_STATUS_FAILED, payload:error });

      }
    };