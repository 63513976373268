import  {
  GET_APPLICATIONS_REQUEST,
  GET_APPLICATIONS_SUCCESS,
  GET_APPLICATIONS_FAILED,
 
  ADD_APPLICATION_REQUEST,
  ADD_APPLICATION_SUCCESS,
  ADD_APPLICATION_FAILED,

  GET_APPLICATION_REQUEST,
  GET_APPLICATION_SUCCESS,
  GET_APPLICATION_FAILED,

  GET_REMINDERLINE_REQUEST,
  GET_REMINDERLINE_SUCCESS,
  GET_REMINDERLINE_FAILED,

  GET_REMINDERLINEEVENTS_REQUEST,
  GET_REMINDERLINEEVENTS_SUCCESS,
  GET_REMINDERLINEEVENTS_FAILED,
  
DELETE_FILE_REQUEST,
DELETE_FILE_SUCCESS,
DELETE_FILE_FAILED,

DELETE_DOSSIER_REQUEST,
DELETE_DOSSIER_SUCCESS,
DELETE_DOSSIER_FAILED,


UPDATE_APPLICATION_REQUEST,
UPDATE_APPLICATION_SUCCESS,
UPDATE_APPLICATION_FAILED,

DOWNLOAD_FILE_REQUEST,
DOWNLOAD_FILE_SUCCESS,
DOWNLOAD_FILE_FAILED,


ADD_FILE_REQUEST,
ADD_FILE_SUCCESS,
ADD_FILE_FAILED,
ALL_FILES_ADDED,


SWITCH_REMINDERLINEEVENTS_REQUEST,
SWITCH_REMINDERLINEEVENTS_SUCCESS,
SWITCH_REMINDERLINEEVENTS_FAILED,

ADDCOMMENT_REMINDERLINE_REQUEST,
ADDCOMMENT_REMINDERLINE_SUCCESS,
ADDCOMMENT_REMINDERLINE_FAILED,


} from '../constants/applicationConstants';

export const applicationReducer = (
  state = {switchreminderLineEventsSuccess:false, errorApplication:"",loadingApplications: false,applications:[],currentReminderLine:{},currentReminderLinefiles:[],currentReminderLinedossiers:[]},
  action
) => {
  switch (action.type) {

    // ALL applications
    case GET_APPLICATIONS_REQUEST:
      return { loadingApplications: true };

    case GET_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loadingApplications: false,
        applications:action.payload
      };

    case GET_APPLICATIONS_FAILED:
      return { loadingApplications: false, errorApplications: action.payload };
  
      //ADD Application

    case ADD_APPLICATION_REQUEST:
      return { loadingAddApplication: true, addApplicationStatus:false , ...state};

    case ADD_APPLICATION_SUCCESS:
      return {
        ...state,
        loadingAddApplication: false,
        ApplicationAdded:action.payload,
        addApplicationStatus : true,
        applications:state.applications?.concat(action.payload),

        
      };

    case ADD_APPLICATION_FAILED:
      return {...state, loadingAddApplication: false,addApplicationStatus:false, errorAddApplication: action.payload };


          
      //UPDATE Application

    case UPDATE_APPLICATION_REQUEST:
      return { loadingUpdateApplication: true , ...state};

    case UPDATE_APPLICATION_SUCCESS:
      return {
        ...state,
        loadingUpdateApplication: false,
        applicationUpdated:action.payload,
        application:action.payload,
        updateApplicationSuccess:true,
        
        
      };

    case UPDATE_APPLICATION_FAILED:
      return {...state, loadingUpdateApplication: false,updateApplicationSuccess:false, errorUpdateApplication: action.payload };


    // GET application by ID
    case GET_APPLICATION_REQUEST:
      return { ...state,loadingApplication: true ,switchreminderLineEventsSuccess:false};

    case GET_APPLICATION_SUCCESS:
      return {
        ...state,
        loadingApplication: false,
        application:action.payload,
      };

    case GET_APPLICATION_FAILED:
      return {...state, loadingApplication: false, errorApplication: 404};



        // GET reminder by ID
    case GET_REMINDERLINE_REQUEST:
      return {...state, loadingGetRemindeLine: true ,currentReminderLine:{},currentReminderLinefiles:[],currentReminderLinedossiers:[]};

    case GET_REMINDERLINE_SUCCESS:
      return {
        ...state,
        loadingGetRemindeLine: false,
        currentReminderLine:action.payload,
        currentReminderLinefiles:action.payload.files  


      };

    case GET_REMINDERLINE_FAILED:
      return {  ...state,loadingGetRemindeLine: false, errorGetReminderLine: action.payload };



        // ADDCOMMENT reminder by ID
    case ADDCOMMENT_REMINDERLINE_REQUEST:
      return {...state, loadingAddCommentRemindeLine: true ,addCommentSuccess:false};

    case ADDCOMMENT_REMINDERLINE_SUCCESS:
      return {
        ...state,
        loadingAddCommentRemindeLine: false,
        addCommentSuccess:true

      };

    case ADDCOMMENT_REMINDERLINE_FAILED:
      return {  ...state,loadingAddCommentRemindeLine: false,addCommentSuccess:false, errorGetReminderLine: action.payload };

    //ADD File

    case ADD_FILE_REQUEST:
      return { loadingAddFile: true , ...state};



      

    case ADD_FILE_SUCCESS:

        
     
    
      return {
        ...state,
        loadingAddFile: false,
        currentReminderLinefiles:state.currentReminderLinefiles?.concat(action.payload)
      };

      case ALL_FILES_ADDED:
        return{
        ...state,
        fileAddeds:[]
        }
  



    case ADD_FILE_FAILED:
      // console.log(action.payload)
      return {...state, loadingAddFile: false, errorAddFile: action.payload };



      case DELETE_FILE_REQUEST:
        return { loadingDeleteFile: true , ...state};

      case DELETE_FILE_SUCCESS:

          
       
        return {
          ...state,
          loadingDeleteFile: false,
          fileDeleted:action.payload,
          currentReminderLinefiles:state.currentReminderLinefiles?.filter((d) => d.id !== action.payload.dossier.id)
        };

    
      case DELETE_FILE_FAILED:
        // console.log(action.payload)
        return {...state, loadingDeleteFile: false, errorDeleteFile: action.payload };



        case DELETE_DOSSIER_REQUEST:
          return { loadingDeleteDossier: true , ...state};
  
        case DELETE_DOSSIER_SUCCESS:
          // console.log(action.payload)
          return {
            ...state,
            loadingDeleteDossier: false,
            applications:state.applications?.filter((d) => d.id !== action.payload.deletedDossier.id)

          }
       
          ;
  
      
        case DELETE_DOSSIER_FAILED:
          // console.log(action.payload)
          return {...state, loadingDeleteDossier: false, errorDeleteDossier: action.payload };











      case DOWNLOAD_FILE_REQUEST:
        return { loadingDownloadFile: true , ...state};

      case DOWNLOAD_FILE_SUCCESS:

        return {
          ...state,
          loadingDownloadFile: false,
          downloadFilePath:action.payload,
        };

    
      case DOWNLOAD_FILE_FAILED:
        return {...state, loadingDownloadFile: false,getreminderLineEventsSuccess:false, errorDownloadFile: action.payload };





        // GET AllReminderLinesEvents
    case GET_REMINDERLINEEVENTS_REQUEST:
      return {...state, loadingGetRemindeLineEvents: true ,reminderLineEvents:[]};

    case GET_REMINDERLINEEVENTS_SUCCESS:
      return {
        ...state,
        loadingGetRemindeLineEvents: false,
        reminderLineEvents:action.payload,
        getreminderLineEventsSuccess:true,


      };

      case GET_REMINDERLINEEVENTS_FAILED:
        return {  ...state,loadingGetRemindeLineEvents: false,getreminderLineEventsSuccess:false, errorGetReminderLineEvents: action.payload };




                  // GET AllReminderLinesEvents
    case SWITCH_REMINDERLINEEVENTS_REQUEST:
      return {...state, loadingSwitchRemindeLineEvents: true ,switchreminderLineEventsSuccess:false};

    case SWITCH_REMINDERLINEEVENTS_SUCCESS:
      return {
        ...state,
        loadingSwitchRemindeLineEvents: false,
        switchreminderLineEventsSuccess:true,


      };

      case SWITCH_REMINDERLINEEVENTS_FAILED:
        return {  ...state,loadingSwitchRemindeLineEvents: false,switchreminderLineEventsSuccess:false, errorSwitchReminderLineEvents: action.payload };


    default:
      return state;
  }
};