import React from 'react'

export default function Loading() {
    return (
        <div id="global-loader" >
                <img src="/assets/images/ttoLoader.svg" className="loader-img" alt="Loader" />
            </div>

    )
}
