import {
    CHANGELIGHTMODE,
    CHANGESIDEBARE,


} from '../constants/appConfigContants';

export const appConfigReducer = (
    state = { light:true, opened:true},
    action
) => {
    switch (action.type) {


        case CHANGELIGHTMODE:
            return {
                ...state,
                mode : !state.mode


            };

        case CHANGESIDEBARE:
            return {
                ...state,
                opened : !state.opened

            };

     
     
            default:
                return state;

    }
};