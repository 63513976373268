import Axios from 'axios';
import { APIURL } from '../../utils'


import {
  GET_DEPARTEMENTS_REQUEST,
  GET_DEPARTEMENTS_SUCCESS,
  GET_DEPARTEMENTS_FAILED,

  ADD_DEPARTEMENT_REQUEST,
  ADD_DEPARTEMENT_SUCCESS,
  ADD_DEPARTEMENT_FAILED,
  DELETE_DEPARTEMENT_REQUEST,
  DELETE_DEPARTEMENT_SUCCESS,
  DELETE_DEPARTEMENT_FAILED,

  EDIT_DEPARTEMENT_REQUEST,
  EDIT_DEPARTEMENT_SUCCESS,
  EDIT_DEPARTEMENT_FAILED,
} from '../constants/departementsConstants';

export const getDepartements = (token) => async (dispatch) => {
  dispatch({
    type: GET_DEPARTEMENTS_REQUEST,
  });
  try {

    const { data } = await Axios.get(`${APIURL}/api/departements`, { headers: { "Authorization": `Bearer ${token}` } });
    // console.log(data)
    dispatch({ type: GET_DEPARTEMENTS_SUCCESS, payload: data });


  } catch (error) {

    dispatch({ type: GET_DEPARTEMENTS_FAILED, payload: error });

  }
};

export  const  removeDepartementAction = (token,departementId) => async (dispatch) => {

  
  dispatch({
    type: DELETE_DEPARTEMENT_REQUEST,
  });
  try {

    const { data } = await Axios.delete(`${APIURL}/api/deleteD/${departementId}`, 
    { headers: { "Authorization": `Bearer ${token}`, 
    'Content-Type': 'multipart/form-data' } });
    dispatch({ type: DELETE_DEPARTEMENT_SUCCESS, payload: data });

  } catch (error) {
    dispatch({ type: DELETE_DEPARTEMENT_FAILED, payload:error });

  }
};


export const addDepartement = (token, nom) => async (dispatch) => {
  dispatch({
    type: ADD_DEPARTEMENT_REQUEST,
  });
  try {

    const { data } = await Axios.post(`${APIURL}/api/departements`, { nom: nom }, { headers: { "Authorization": `Bearer ${token}` } });

    dispatch({ type: ADD_DEPARTEMENT_SUCCESS, payload: data });

  } catch (error) {

    dispatch({ type: ADD_DEPARTEMENT_FAILED, payload: error });

  }
};


export const editDepartementAction = (token, departement) => async (dispatch) => {
  dispatch({
    type: EDIT_DEPARTEMENT_REQUEST,
  });
  try {

    const { data } = await Axios.put(`${APIURL}/api/departements/${departement.id}`, departement, { headers: { "Authorization": `Bearer ${token}` } });

    dispatch({ type: EDIT_DEPARTEMENT_SUCCESS, payload: data });

  } catch (error) {

    dispatch({ type: EDIT_DEPARTEMENT_FAILED, payload: error });

  }
};