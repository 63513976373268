import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { APIURL } from '../utils'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import Footer from '../components/footer';
import LeftMenu from '../components/leftMenu';
import TopBar from '../components/topBar';
import { getApplication, addFile, getReminiderLine, removeFileAction, switchRminderLines, switchRminderLines1, switchRminderLines12, switchRminderLines2, switchRminderLines3, updateApplicationAction, addCommentReminiderLine } from '../redux/actions/applicationAction';
import { useToasts } from 'react-toast-notifications';

import Loading from '../components/loading';
import Moment from 'react-moment';
import Modal from "react-bootstrap/Modal";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import 'jquery/dist/jquery.min';
import { Dropzone, FileItem } from "@dropzone-ui/react";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



function ApplicationDetails(props) {

    const ref = useRef(null);
    const { addToast } = useToasts();
    const [commentReminderLine, setCommentReminderLine] = useState("")

    const { loadingApplication, switchreminderLineEventsSuccess, errorApplication, application, currentReminderLinefiles, currentReminderLine, loadingGetRemindeLine, updateApplicationSuccess } = useSelector((state) => state.applications);
    const { statutes } = useSelector((state) => state.statutes);

    const { token, role } = useSelector((state) => state.userInfo);
    const [files, setFiles] = useState([]);
    const [editMode, setEditMode] = useState(false);

    const editedApplication = useSelector((state) => state.applications.application);


    const [openDetailsSection, setOpenDetailsSection] = useState(false);
    const [loadingOpenDetailsSection, setLoadingOpenDetailsSection] = useState(false);


    const [openModal, setOpenModal] = useState(false);
    const [viewFileId, setViewFileId] = useState("");

    const dispatch = useDispatch()
    const { id } = useParams();

    const [updateApplication, setUpdateApplication] = useState({ id: "", ref: "", statue_id: "", project_title: "", date_de_depot: "", Numero_depot: "", researcher_id: "", comment: "" });




    const showModal = (id) => {
        setViewFileId(id);
        setOpenModal(true);
    };

    const hideModal = () => {
        setOpenModal(false);
    };

    const scrollDown = () => {
        setTimeout(() => {
            ref.current?.scrollIntoView({ behavior: 'smooth' });

        }, 1000);
    };

    useEffect(() => {

        if (application === undefined || (application && switchreminderLineEventsSuccess === true)) {
            dispatch(getApplication(token, id));
            // dispatch(getStatutes(token));
        } else {
            // console.log(application)


            setUpdateApplication({ id: application.id, ref: application.ref, statue_id: application.statue_id, project_title: application.project_title, date_de_depot: application.date_de_depot, Numero_depot: application.Numero_depot, researcher_id: application.researcher_id, comment: application.comment })

        }
        if (errorApplication) {
            //    props.history.push('/')
        }




        if (updateApplicationSuccess) {

            addToast("The patent has been modified successfully", { autoDismiss: true, appearance: 'success', placement: "bottom-center" });
        }


        setFiles([])


    }
        , [application, errorApplication, dispatch, updateApplicationSuccess, switchreminderLineEventsSuccess])

    const reminderLineDetails = (reminderLineId) => {

        dispatch(getReminiderLine(token, reminderLineId))

        setOpenDetailsSection(false)
        setLoadingOpenDetailsSection(true)

        setTimeout(() => {
            setCommentReminderLine("")
            setLoadingOpenDetailsSection(false)
            setOpenDetailsSection(true)
        }, 1500);



    }


    const updateFiles = (incommingFiles) => {

        //do something with the files
        setFiles(incommingFiles);
    };
    const removeFileFromDrag = (id) => {
        setFiles(files.filter((x) => x.id !== id));
    };


    const removeFileFromFiles = (fileId) => {

        dispatch(removeFileAction(token, fileId))
        addToast("The file has been deleted", { autoDismiss: true, appearance: 'success', placement: "bottom-center" });

    };

    const addFileToServer = () => {

        files.forEach(file => {
            const data = new FormData()
            data.append('path', file.file)
            data.append('reminder_line_id', currentReminderLine.id)
            dispatch(addFile(token, data))
        });
        setFiles([]);
    }

    const addCommentReminiderLinehundler = (comment) => {

        dispatch(addCommentReminiderLine(token, currentReminderLine.id, comment))
        addToast("The comment has been created", { autoDismiss: true, appearance: 'success', placement: "bottom-center" });
        setOpenDetailsSection(false)
        setLoadingOpenDetailsSection(true)
        setTimeout(() => {
            setLoadingOpenDetailsSection(false)
            setOpenDetailsSection(true)
        }, 1500);

    }

    const getImageFormExtention = (name) => {

        switch (name.split('.')[1]) {
            case "jpeg":
                return "img.png"
            case "jpg":
                return "img.png"
            case "png":
                return "img.png"
            case "pdf":
                return "pdf.png"
            case "xls":
                return "excel.png"
            case "xlsx":
                return "excel.png"
            case "doc":
                return "doc.png"
            case "zip":
                return "zip.png"
            case "rar":
                return "rar.png"
            case "docx":
                return "doc.png"
            case "ppt":
                return "ppt.png"
            case "pptx":
                return "pptx.png"
            default:
                return "file.png"
        }
    }

    const updateApplicationHandler = () => {

        dispatch(updateApplicationAction(token, updateApplication));
        // console.log(updateApplication)

    }
    const { opened } = useSelector((state) => state.appConfig);

    const switchReminderLine = (currentId, order) => {


        // alert(order)
        const rlCopy = application.reminder_lines.sort((a, b) => new Date(a.event_date) - new Date(b.event_date))
        if (order < 17) {
            const nextRlId = rlCopy.findIndex(e => e.id == currentId) + 1
            const next_reminder_lineid = rlCopy[nextRlId].id
            dispatch(switchRminderLines(token, currentId, next_reminder_lineid));
            addToast("The phase has been done", { autoDismiss: true, appearance: 'success', placement: "bottom-center" });
        }
        else {

            const next_reminder_lineid = 9999999
            dispatch(switchRminderLines(token, currentId, next_reminder_lineid));

        }






    }
    const switchReminderLine1 = (currentId, order) => {


        // alert(order)
        const rlCopy = application.reminder_lines.sort((a, b) => new Date(a.event_date) - new Date(b.event_date))

        const nextRlId = rlCopy.findIndex(e => e.id == currentId)
        const next_reminder_lineid = rlCopy[nextRlId].id
        dispatch(switchRminderLines1(token, currentId, next_reminder_lineid));
        addToast("The phase was satisfactory", { autoDismiss: true, appearance: 'success', placement: "bottom-center" });
    }
    const switchReminderLine12 = (currentId, order) => {


        // alert(order)
        const rlCopy = application.reminder_lines.sort((a, b) => new Date(a.event_date) - new Date(b.event_date))

        const nextRlId = rlCopy.findIndex(e => e.id == currentId)
        const next_reminder_lineid = rlCopy[nextRlId].id
        dispatch(switchRminderLines12(token, currentId, next_reminder_lineid));
        addToast("The phase has been done", { autoDismiss: true, appearance: 'success', placement: "bottom-center" });
    }

    const switchReminderLine3 = (currentId, order) => {


        // alert(order)
        const rlCopy = application.reminder_lines.sort((a, b) => new Date(a.event_date) - new Date(b.event_date))

        const nextRlId = rlCopy.findIndex(e => e.id == currentId)
        const next_reminder_lineid = rlCopy[nextRlId].id
        dispatch(switchRminderLines3(token, currentId, next_reminder_lineid));
        addToast("The phase has not been done", { autoDismiss: true, appearance: 'success', placement: "bottom-center" });
    }
    const switchReminderLine2 = (currentId, order) => {


        // alert(order)
        const rlCopy = application.reminder_lines.sort((a, b) => new Date(a.event_date) - new Date(b.event_date))

        const nextRlId = rlCopy.findIndex(e => e.id == currentId)
        const next_reminder_lineid = rlCopy[nextRlId].id
        dispatch(switchRminderLines2(token, currentId, next_reminder_lineid));
        addToast("The phase was satisfactory", { autoDismiss: true, appearance: 'success', placement: "bottom-center" });
    }

    return (

        <div className="horizontalMenucontainer">

            <div className="page">
                <div className="page-main"></div>
                <TopBar />
                <LeftMenu />

                <div className="main-content app-content mt-0" style={{ marginLeft: opened ? "270px" : "0px" }}>
                    <div className="side-app">


                        <div className="main-container container-fluid">
                            <div className="page-header">
                                <div>
                                    <ol className="breadcrumb" style={{ marginTop: '71px', marginLeft: '29px' }}>
                                        <li className="breadcrumb-item"><a href="http://localhost:3000/">Dashboard</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Patent details</li>
                                    </ol>
                                </div>
                            </div>
                            {errorApplication == 404 ? <>
                                <div className="container text-center">
                                    <div className="error-template">
                                        <h1 className="display-1 mb-2">4<span className="custom-emoji"><svg xmlns="http://www.w3.org/2000/svg" height="140" width="140" data-name="Layer 1" viewBox="0 0 24 24"><circle cx="12" cy="12" r="10" fill="#a2a1ff"></circle><path fill="#6563ff" d="M15.999,17a.99764.99764,0,0,1-.59912-.2002l-.7334-.5498-.73291.5498a.99755.99755,0,0,1-1.20019,0L12,16.25l-.7334.5498a.9999.9999,0,0,1-1.20019-1.5996l1.33349-1a.99757.99757,0,0,1,1.2002,0l.7334.5498.73291-.5498a.99755.99755,0,0,1,1.20019,0l1.3335,1A1.00013,1.00013,0,0,1,15.999,17Z"></path><path fill="#6563ff" d="M13.33252 17a.9976.9976 0 0 1-.59912-.2002L12 16.25l-.7334.5498a.99755.99755 0 0 1-1.20019 0L9.3335 16.25l-.7334.5498a.9999.9999 0 0 1-1.2002-1.5996l1.3335-1a.99755.99755 0 0 1 1.20019 0l.73291.5498.7334-.5498a.99757.99757 0 0 1 1.2002 0l1.33349 1A1.00013 1.00013 0 0 1 13.33252 17zM8.37109 12.5a1 1 0 0 1-.707-1.707L8.457 10l-.793-.793A.99989.99989 0 0 1 9.07812 7.793l1.5 1.5a.99962.99962 0 0 1 0 1.41406l-1.5 1.5A.99676.99676 0 0 1 8.37109 12.5zM15.87109 12.5a.99678.99678 0 0 1-.707-.293l-1.5-1.5a.99964.99964 0 0 1 0-1.41406l1.5-1.5A.99989.99989 0 0 1 16.57812 9.207l-.793.793.793.793a1 1 0 0 1-.707 1.707z"></path></svg></span>4</h1>
                                        <h5 className="error-details">
                                            Project not found!
                                        </h5>
                                        <div className="text-center">
                                            <Link to={{ pathname: "/" }}
                                            >
                                                <div className="btn btn-primary mt-5 mb-5" > <i className="fa fa-long-arrow-left"></i> Return to Dashboard </div>

                                            </Link>
                                        </div>
                                    </div>
                                </div></> : ""}
                            {loadingApplication ? <Loading></Loading> : <></>}
                            {application && editedApplication ?
                                <div className="row" id="user-profile">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-xl-9">

                                                <div className="card">
                                                    <div className="card-header">
                                                        <span><i className="fe fe-file-text fs-30 " style={{ color: "#188acf" }}></i></span>    <div className="card-title"  > Patent information</div>
                                                        {role == "admin" ?
                                                            <div className="card-options">
                                                                <div onClick={(e) => { e.preventDefault(); setEditMode(true) }} style={{ display: editMode ? "none" : "block" }} className="btn btn-primary btn-sm" >Modify the patent</div>


                                                            </div>
                                                            : <></>
                                                        }
                                                    </div>



                                                    <div className="card-body" >

                                                        <div className="row">


                                                            <div className="col-xl-12" style={{ display: editMode ? "none" : "block" }}>

                                                                <div className="d-flex align-items-center mb-3 mt-3" >
                                                                    <div className="me-4 text-center text-primary">

                                                                    </div>

                                                                    <strong >   Patent title : <span style={{ fontWeight: '400' }}>{application.project_title}</span> </strong>


                                                                </div>

                                                                <div className="d-flex align-items-center mb-3 mt-3">
                                                                    <div className="me-4 text-center text-primary">

                                                                    </div>

                                                                    <strong  >   Patent reference : </strong> {application.ref}

                                                                </div>

                                                                <div className="d-flex align-items-center mb-3 mt-3">
                                                                    <div className="me-4 text-center text-primary" >

                                                                    </div>

                                                                    <strong  >   Filing date : </strong> {application.date_de_depot}


                                                                </div>

                                                                <div className="d-flex align-items-center mb-3 mt-3">
                                                                    <div className="me-4 text-center text-primary">

                                                                    </div>

                                                                    <strong  >   Filing number : </strong> {application.Numero_depot}


                                                                </div>

                                                                <div className="d-flex align-items-center mb-3 mt-3">
                                                                    <div className="me-4 text-center text-primary">

                                                                    </div>

                                                                    <strong >   Status : </strong>

                                                                    {statutes != undefined && statutes.length > 0 ?
                                                                        statutes?.filter(item => item.id === application?.statue_id)[0].name
                                                                        : <></>
                                                                    }


                                                                </div>

                                                                <div className="d-flex align-items-center mb-3 mt-3">
                                                                    <div className="me-4 text-center text-primary">

                                                                    </div>
                                                                    <div>
                                                                        <strong > Comments : </strong>
                                                                        <div dangerouslySetInnerHTML={{ __html: application.comment }} />



                                                                    </div>
                                                                </div>

                                                            </div>


                                                            <div className="col-xl-12" style={{ display: editMode ? "block" : "none" }}>

                                                                <form className="form-horizontal">
                                                                    <div className=" row mb-4">
                                                                        <label className="col-md-3 form-label"> Patent title :</label>
                                                                        <div className="col-md-9">
                                                                            <input name="project_title" id="project_title" className="form-control form-control-sm mb-4"
                                                                                value={updateApplication.project_title} type="text"
                                                                                onChange={(e) => { setUpdateApplication({ ...updateApplication, "project_title": e.target.value }) }}
                                                                            ></input>
                                                                        </div>
                                                                    </div>


                                                                    <div className=" row mb-4">
                                                                        <label className="col-md-3 form-label">Patent reference :</label>
                                                                        <div className="col-md-9">
                                                                            <input className="form-control form-control-sm mb-4" type="text"
                                                                                value={updateApplication.ref}
                                                                                onChange={(e) => { setUpdateApplication({ ...updateApplication, "ref": e.target.value }) }}
                                                                            ></input>
                                                                        </div>
                                                                    </div>




                                                                    <div className=" row mb-4">
                                                                        <label className="col-md-3 form-label">Filing date :</label>
                                                                        <div className="col-md-9">
                                                                            <input className="form-control form-control-sm mb-4" type="date"
                                                                                value={updateApplication.date_de_depot}
                                                                                onChange={(e) => { setUpdateApplication({ ...updateApplication, "date_de_depot": e.target.value }) }}
                                                                            ></input>
                                                                        </div>
                                                                    </div>



                                                                    <div className=" row mb-4">
                                                                        <label className="col-md-3 form-label">  Filing number :</label>
                                                                        <div className="col-md-9">
                                                                            <input className="form-control form-control-sm mb-4" type="text"
                                                                                value={updateApplication.Numero_depot || ""}
                                                                                onChange={(e) => { setUpdateApplication({ ...updateApplication, "Numero_depot": e.target.value }) }}
                                                                            ></input>
                                                                        </div>
                                                                    </div>

                                                                    <div className=" row mb-4">
                                                                        <label className="col-md-3 form-label">  Status :</label>
                                                                        <div className="col-md-9">
                                                                            <select name="status" className="form-control form-control-sm mb-4 form-select" data-bs-placeholder="Select"
                                                                                value={updateApplication.statue_id}
                                                                                onChange={(e) => { setUpdateApplication({ ...updateApplication, "statue_id": parseInt(e.target.value) }) }}

                                                                                required       >
                                                                                {statutes != undefined && statutes.length > 0 ?
                                                                                    statutes.map((item, index) => {
                                                                                        return <option key={item.id} value={parseInt(item.id)} selected={item.id == application.statue_id}>{item.name}</option>
                                                                                    })
                                                                                    : <option value=""></option>
                                                                                }

                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div className=" row mb-4">
                                                                        <label className="col-md-3 form-label">  Comments :</label>
                                                                        <div className="col-md-9">
                                                                            <CKEditor
                                                                                editor={ClassicEditor}
                                                                                data={application.comment}
                                                                                onReady={editor => {
                                                                                    // You can store the "editor" and use when it is needed.
                                                                                    // console.log('Editor is ready to use!', editor);
                                                                                }}
                                                                                onChange={(event, editor) => {
                                                                                    const data = editor.getData();
                                                                                    setUpdateApplication({ ...updateApplication, "comment": data })
                                                                                }}

                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12">
                                                                        <button className="btn btn-primary " style={{ float: "right" }}
                                                                            onClick={(e) => {
                                                                                e.preventDefault(); setEditMode(false);
                                                                                updateApplicationHandler()
                                                                            }}
                                                                        >Confirm</button>


                                                                        <button onClick={(e) => {
                                                                            e.preventDefault(); setEditMode(false);
                                                                            setUpdateApplication({ id: application.id, ref: application.ref, statue_id: application.statue_id, project_title: application.project_title, date_de_depot: application.date_de_depot, Numero_depot: application.Numero_depot, researcher_id: application.researcher_id })
                                                                        }}
                                                                            className="btn btn-warning
                                                                        " style={{ float: "left" }}>Cancel</button>
                                                                    </div>


                                                                </form>


                                                            </div>



                                                        </div>
                                                    </div>
                                                </div>




                                            </div>


                                            <div className="col-xl-3">

                                                <div className="card">
                                                    <div className=" profile-details-main ps ps--active-y">
                                                        <div className="card-body pb-0">
                                                            <div className="">


                                                                <div className="text-center chat-image p-4 pb-0 mb-4 br-5">
                                                                    <div className="avatar avatar-xxl chat-profile mb-3 brround">
                                                                        <div className=""><img alt="avatar" src="/assets/images/profile.png" className="brround" /></div>
                                                                    </div>
                                                                    <div className="main-chat-msg-name">
                                                                        <div>
                                                                            <h5 className="mb-1 text-dark fw-semibold">{application?.researcher?.nom} {application?.researcher?.prenom}</h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <h6 className="mb-3">Contact Details :</h6>
                                                                    <div className="d-flex mb-2">
                                                                        <div>
                                                                            <i className="fe fe-mail"></i>
                                                                        </div>
                                                                        <div className="ms-2">
                                                                            <p className="fs-13 fw-semibold mb-0">Email</p>
                                                                            <p className="fs-12 text-muted">{application?.researcher?.email} </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex mb-2 mt-2">
                                                                        <div>
                                                                            <i className="fe fe-phone"></i>
                                                                        </div>
                                                                        <div className="ms-2">
                                                                            <p className="fs-13 fw-semibold mb-0">Phone</p>
                                                                            <p className="fs-12 text-muted">{application?.researcher?.phone_number} </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex mb-2 mt-2">
                                                                        <div>
                                                                            <i className="fe fe-map-pin"></i>
                                                                        </div>
                                                                        <div className="ms-2">
                                                                            <p className="fs-13 fw-semibold mb-0">Entity</p>
                                                                            <p className="fs-12 text-muted">{application?.department?.nom}</p>
                                                                        </div>
                                                                    </div>

                                                                </div>



                                                            </div>
                                                        </div>

                                                    </div>


                                                </div>





                                            </div>
                                            <div className="col-xl-9">

                                                <div className="card" >
                                                    <div className="card-body">
                                                        <h4 className="card-title" >Timeline</h4>
                                                        <div className="container">

                                                            {application.date_de_depot ?
                                                                <ul className="notification">
                                                                    {application.reminder_lines.sort((a, b) => new Date(a.event_date) - new Date(b.event_date)).map((item, index) => {
                                                                        return (
                                                                            <>
                                                                                {(() => {
                                                                                    if (item.done == 1) {
                                                                                        return (
                                                                                            <li>
                                                                                                <div className="notification-time">
                                                                                                    <span className="date"> <Moment format="YYYY/MM/DD" >{item.event_date}</Moment></span>
                                                                                                    <span style={{ fontSize: "10 !important" }} > Completed</span>

                                                                                                </div>
                                                                                                <div className="notification-icon">
                                                                                                    <span className="badge bg-success side-badge" style={{
                                                                                                        position: "initial",
                                                                                                        width: "30px",
                                                                                                        height: "30px"
                                                                                                    }}>
                                                                                                        <i className="fa fa-check" style={{ fontSize: "20px" }} data-bs-toggle="tooltip" title="" data-bs-original-title="fa fa-check" aria-label="fa fa-check">
                                                                                                        </i>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div className="notification-time-date mb-2 d-block d-md-none">
                                                                                                    <span className="time" style={{ fontSize: "12 !important" }} > Completed</span>
                                                                                                </div>
                                                                                                <div className="notification-body" style={{ backgroundColor: "rgba(147, 147, 147, 0.16)", marginRight: "0px" }}>
                                                                                                    <div className="media mt-0">
                                                                                                        <div className="main-avatar avatar-md offline">
                                                                                                            <span style={{ backgroundColor: "#0aad95" }} className="avatar avatar-md bradius me-3">T{index + 1}</span>
                                                                                                        </div>
                                                                                                        <div className="media-body ms-3 d-flex">
                                                                                                            <div className="">
                                                                                                                <p className="fs-15 text-dark fw-bold mb-0" style={{ maxWidth: "100%" }} >{item.reminder_type.description}</p>
                                                                                                            </div>
                                                                                                            <div className="notify-time">
                                                                                                                <button type="button" onClick={(e) => { e.preventDefault(); reminderLineDetails(item.id); scrollDown() }} className="btn btn-sm btn-secondary"><i className="fa fa-plus me-2"></i>Details</button>

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        )
                                                                                    }

                                                                                    else if (item.done == 0 && item.actif == 0 && item.done_event == 0) {
                                                                                        return (
                                                                                            <li>
                                                                                                <div className="notification-time">
                                                                                                    <span className="date"> <Moment format="YYYY/MM/DD" >{item.event_date}</Moment></span>
                                                                                                    <span style={{ fontSize: "10 !important" }} > Not started yet</span>

                                                                                                </div>

                                                                                                <div className="notification-icon">
                                                                                                    <a href="javascript:void(0);" style={{ border: "#05c3fb 3px solid " }}></a>
                                                                                                </div>

                                                                                                <div className="notification-time-date mb-2 d-block d-md-none">
                                                                                                </div>
                                                                                                <div className="notification-body" style={{ backgroundColor: "rgba(147, 147, 147, 0.16)", marginRight: "0px" }}>
                                                                                                    <div className="media mt-0">
                                                                                                        <div className="main-avatar avatar-md offline">
                                                                                                            <span style={{ backgroundColor: "rgb(5 195 251 / 47%)" }} className="avatar avatar-md bradius me-3">T{index + 1}</span>
                                                                                                        </div>
                                                                                                        <div className="media-body ms-3 d-flex">
                                                                                                            <div className="">
                                                                                                                <p className="fs-15 text-dark fw-bold mb-0" style={{ maxWidth: "100%" }} >{item.reminder_type.description}</p>
                                                                                                            </div>
                                                                                                            <div className="notify-time">
                                                                                                                <button type="button" onClick={(e) => { e.preventDefault(); reminderLineDetails(item.id); scrollDown() }} className="btn btn-sm btn-secondary"><i className="fa fa-plus me-2"></i>Details</button>

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        )
                                                                                    }

                                                                                    else if (item.done == 0 && item.actif == -1 && item.done_event == 0) {
                                                                                        return (
                                                                                            <li>
                                                                                                <div className="notification-time">
                                                                                                    <span className="date"> <Moment format="YYYY/MM/DD" >{item.event_date}</Moment></span>
                                                                                                    <span style={{ fontSize: "10 !important" }} >Cancelled step</span>

                                                                                                </div>

                                                                                                <div className="notification-icon">
                                                                                                    <a href="javascript:void(0);" style={{ border: "gray 3px solid " }}></a>
                                                                                                </div>

                                                                                                <div className="notification-time-date mb-2 d-block d-md-none">
                                                                                                </div>
                                                                                                <div className="notification-body" style={{ backgroundColor: "rgba(147, 147, 147, 0.16)", marginRight: "0px" }}>
                                                                                                    <div className="media mt-0">
                                                                                                        <div className="main-avatar avatar-md offline">
                                                                                                            <span style={{ backgroundColor: "gray" }} className="avatar avatar-md bradius me-3">T{index + 1}</span>
                                                                                                        </div>
                                                                                                        <div className="media-body ms-3 d-flex">
                                                                                                            <div className="">
                                                                                                                <p className="fs-15 text-dark fw-bold mb-0" style={{ maxWidth: "100%" }} >{item.reminder_type.description}</p>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        )
                                                                                    }
                                                                                    else if (item.done == 0 && item.actif == 1 && item.done_event == 0) {
                                                                                        return (
                                                                                            <li>
                                                                                                <div className="notification-time">
                                                                                                    <span className="date"> <Moment format="YYYY/MM/DD" >{item.event_date}</Moment></span>
                                                                                                    <span style={{ fontSize: "10 !important" }} > In progress..</span>

                                                                                                </div>
                                                                                                <div className="notification-icon">
                                                                                                    <div className="spinner-grow " style={{ color: "#ff7062" }} role="status">
                                                                                                        <span className="visually-hidden">Loading...</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="notification-time-date mb-2 d-block d-md-none">
                                                                                                </div>
                                                                                                <div className="notification-body" style={{ backgroundColor: "rgba(147, 147, 147, 0.16)", marginRight: "0px" }} >
                                                                                                    <div className="media mt-0">
                                                                                                        <div className="main-avatar avatar-md offline">
                                                                                                            <span style={{ backgroundColor: "#ff7062" }} className="avatar avatar-md bradius me-3">T{index + 1}</span>
                                                                                                        </div>
                                                                                                        <div className="media-body ms-3 d-flex">
                                                                                                            <div className="">
                                                                                                                {item.reminder_type.order != 3 && item.reminder_type.order != 7 ?
                                                                                                                    <p className="fs-15 text-dark fw-bold mb-0" style={{ maxWidth: "100%" }} >{item.reminder_type.description}</p>
                                                                                                                    : <></>}
                                                                                                                {item.reminder_type.order == 3 ?
                                                                                                                    <p className="fs-15 text-dark fw-bold mb-0" style={{ maxWidth: "55%" }} >{item.reminder_type.description}</p>
                                                                                                                    : <></>}
                                                                                                                {item.reminder_type.order == 7 ?
                                                                                                                    <p className="fs-15 text-dark fw-bold mb-0" style={{ maxWidth: "64%" }} >{item.reminder_type.description}</p>
                                                                                                                    : <></>}
                                                                                                            </div>
                                                                                                            <div className="notify-time">
                                                                                                                <button style={{ marginRight: "5px" }} type="button" onClick={(e) => { e.preventDefault(); reminderLineDetails(item.id); scrollDown() }} className="btn btn-sm btn-secondary"><i className="fa fa-plus me-2"></i>Details</button>
                                                                                                                {role == "admin" && item.reminder_type.order != 3 && item.reminder_type.order != 7 && item.reminder_type.order != 17 ?
                                                                                                                    <button type="button" onClick={(e) => { e.preventDefault(); switchReminderLine(item.id, item.reminder_type.order) }} className="btn btn-sm btn-primary"><i className="fa fa-check me-2"></i>Done?</button>

                                                                                                                    : <></>}
                                                                                                                {role == "admin" && item.reminder_type.order == 5 ?
                                                                                                                    <button type="button" onClick={(e) => { e.preventDefault(); switchReminderLine3(item.id, item.reminder_type.order) }} className="btn btn-sm btn-primary"><i className="fa fa-check me-2"></i> Not done?</button>

                                                                                                                    : <></>}

                                                                                                                {role == "admin" && item.reminder_type.order == 3 ?
                                                                                                                    <button type="button" style={{ marginRight: '-19px' }} onClick={(e) => { e.preventDefault(); switchReminderLine1(item.id, item.reminder_type.order) }} className="btn btn-sm btn-primary"><i className="fa fa-check me-2"></i>  Satisfactory?</button>

                                                                                                                    : <></>}
                                                                                                                {role == "admin" && item.reminder_type.order == 3 ?
                                                                                                                    <button type="button" style={{ marginRight: '-14px', marginLeft: '23px' }} onClick={(e) => { e.preventDefault(); switchReminderLine(item.id, item.reminder_type.order) }} className="btn btn-sm btn-primary"><i className="fa fa-check me-2"></i> Not satisfactory?</button>

                                                                                                                    : <></>}
                                                                                                                {role == "admin" && item.reminder_type.order == 7 ?
                                                                                                                    <button type="button" style={{ marginRight: '-19px' }} onClick={(e) => { e.preventDefault(); switchReminderLine2(item.id, item.reminder_type.order) }} className="btn btn-sm btn-primary"><i className="fa fa-check me-2"></i> Satisfactory?</button>

                                                                                                                    : <></>}
                                                                                                                {role == "admin" && item.reminder_type.order == 7 ?
                                                                                                                    <button type="button" style={{ marginRight: '-14px', marginLeft: '23px' }} onClick={(e) => { e.preventDefault(); switchReminderLine(item.id, item.reminder_type.order) }} className="btn btn-sm btn-primary"><i className="fa fa-check me-2"></i> Not satisfactory?</button>

                                                                                                                    : <></>}
                                                                                                                {role == "admin" && item.reminder_type.order == 17 ?
                                                                                                                    <button type="button" onClick={(e) => { e.preventDefault(); switchReminderLine12(item.id, item.reminder_type.order) }} className="btn btn-sm btn-primary"><i className="fa fa-check me-2"></i> Done?</button>

                                                                                                                    : <></>}



                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        )
                                                                                    } else if (item.done == 0 && item.actif == 0 && item.done_event == 1) {
                                                                                        return (<li>
                                                                                            <div className="notification-time">
                                                                                                <span className="date"> <Moment format="YYYY/MM/DD" >{item.event_date}</Moment></span>
                                                                                                <span style={{ fontSize: "10 !important" }} > Already done?</span>

                                                                                            </div>
                                                                                            <div className="notification-icon">
                                                                                                <span className="badge bg-danger side-badge" style={{
                                                                                                    position: "initial",
                                                                                                    width: "30px",
                                                                                                    height: "30px"
                                                                                                }}>
                                                                                                    <i className="fe fe-alert-triangle" style={{ fontSize: "20px" }} data-bs-toggle="tooltip" title="" data-bs-original-title="fa fa-check" aria-label="fa fa-check">
                                                                                                    </i>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="notification-time-date mb-2 d-block d-md-none">
                                                                                                <span className="time" style={{ fontSize: "12 !important" }} > Completed</span>
                                                                                            </div>
                                                                                            <div className="notification-body" style={{ backgroundColor: "rgba(147, 147, 147, 0.16)", marginRight: "0px" }} >
                                                                                                <div className="media mt-0">
                                                                                                    <div className="main-avatar avatar-md offline">
                                                                                                        <span className="avatar avatar-md bg-danger bradius me-3">T{index + 1}</span>
                                                                                                    </div>
                                                                                                    <div className="media-body ms-3 d-flex">
                                                                                                        <div className="">
                                                                                                            <p className="fs-15 text-dark fw-bold mb-0" style={{ maxWidth: "100%" }} >{item.reminder_type.description}</p>
                                                                                                        </div>
                                                                                                        <div className="notify-time">
                                                                                                            <button style={{ marginRight: "5px" }} type="button" onClick={(e) => { e.preventDefault(); reminderLineDetails(item.id); scrollDown() }} className="btn btn-sm btn-secondary"><i className="fa fa-plus me-2"></i>Details</button>
                                                                                                            {role == "admin" ?
                                                                                                                <button type="button" onClick={(e) => { e.preventDefault(); switchReminderLine(item.id, item.reminder_type.order) }} className="btn btn-sm btn-primary"><i className="fa fa-check me-2"></i>Done?</button>
                                                                                                                : <></>}


                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </li>)
                                                                                    }

                                                                                    else {
                                                                                        return (
                                                                                            <div></div>
                                                                                        )
                                                                                    }
                                                                                })()}
                                                                            </>
                                                                        )
                                                                    })}


                                                                    {application.reminder_lines.sort((a, b) => new Date(a.event_date) - new Date(b.event_date)).map((item, index) => {

                                                                        return <></>
                                                                        return (
                                                                            <li key={item.id} >
                                                                                <div className="notification-time">
                                                                                    <span className="date"> <Moment format="YYYY/MM/DD" >{item.event_date}</Moment></span>
                                                                                    {item.done == 1 ?
                                                                                        <span style={{ fontSize: "10 !important" }} > Completed</span>
                                                                                        :

                                                                                        <>{item.actif == 1 ?
                                                                                            <span style={{ fontSize: "10 !important" }} >In progress</span>
                                                                                            :
                                                                                            <span style={{ fontSize: "10 !important" }} >Not started yet</span>

                                                                                        }    </>
                                                                                    }


                                                                                    {item.done == 0 && new Date(item.event_date) < new Date() ?
                                                                                        <span style={{ fontSize: "10 !important" }} > Outdated</span>
                                                                                        : ""
                                                                                    }


                                                                                </div>
                                                                                <div className="notification-icon" >

                                                                                    {item.done == 1 ?
                                                                                        <span className="badge bg-success side-badge" style={{
                                                                                            position: "initial",
                                                                                            width: "30px",
                                                                                            height: "30px"
                                                                                        }}>
                                                                                            <i className="fa fa-check" style={{ fontSize: "20px" }} data-bs-toggle="tooltip" title="" data-bs-original-title="fa fa-check" aria-label="fa fa-check">
                                                                                            </i>
                                                                                        </span> :

                                                                                        <>{item.actif == 1 ?
                                                                                            <div className="spinner-grow " style={{ color: "#ff7062" }} role="status">
                                                                                                <span className="visually-hidden">Loading...</span>
                                                                                            </div>
                                                                                            :
                                                                                            <div></div>

                                                                                        }    </>
                                                                                    }


                                                                                </div>
                                                                                <div className="notification-time-date mb-2 d-block d-md-none">
                                                                                    <span className="date">Yesterday</span>

                                                                                </div>
                                                                                <div className="notification-body" style={{ backgroundColor: "rgba(147, 147, 147, 0.16)", marginRight: "0px" }} >
                                                                                    <div className="media mt-0">
                                                                                        <div className="main-avatar avatar-md online">
                                                                                            {item.done == 1 ?
                                                                                                <span style={{ backgroundColor: "#09ad95" }} className="avatar avatar-md bradius me-3">T{index + 1}</span>
                                                                                                :
                                                                                                <span style={item.actif == 0 ? { backgroundColor: "gray" } : { backgroundColor: "#ff7062" }} className="avatar avatar-md bradius me-3">T{index + 1}</span>

                                                                                            }
                                                                                        </div>
                                                                                        <div className="media-body ms-3 d-flex" >
                                                                                            <div className="">
                                                                                                <p className="fs-15 text-dark fw-bold mb-0" style={{ maxWidth: "100%" }} >{item.reminder_type.description}</p>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="notify-time">
                                                                                            <button type="button" onClick={(e) => { e.preventDefault(); reminderLineDetails(item.id); scrollDown() }} className="btn btn-sm btn-secondary"><i className="fa fa-plus me-2"></i>Details</button>
                                                                                        </div>




                                                                                    </div>
                                                                                </div>

                                                                            </li>)
                                                                    })}

                                                                </ul>
                                                                : <>Please define the filing date to generate the timeline</>}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="dimmer active" style={loadingOpenDetailsSection ? { display: "block" } : { display: "none" }}>
                                                    <div className="spinner2">
                                                        <div className="cube1"></div>
                                                        <div className="cube2"></div>
                                                    </div>
                                                </div>

                                                <div className="card card-headpills " style={openDetailsSection ? { display: "block" } : { display: "none" }}>

                                                    {currentReminderLine != undefined ?

                                                        <div className="card">
                                                            <div className="card-header">
                                                                <h3 className="card-title">Phase details " <b style={{ color: "#ff7062" }}>{currentReminderLine?.remindertype?.description}</b> " </h3>
                                                            </div>
                                                            <div className="card-body">



                                                                <div className="panel panel-primary">
                                                                    <div className="tab-menu-heading">
                                                                        <div className="tabs-menu">

                                                                            <ul className="nav panel-tabs panel-info">
                                                                                <li><a href="#tab21" className="active" data-bs-toggle="tab"><span><i className="fe fe-navigation me-1"></i></span>Notifications</a></li>
                                                                                <li><a href="#tab22" data-bs-toggle="tab" className=""><span><i className="fe fe-folder me-1"></i></span>Documents </a></li>
                                                                                <li><a href="#tab23" data-bs-toggle="tab" className=""><span><i className="fe fe-message-circle me-1"></i></span>Comments</a></li>

                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="panel-body tabs-menu-body">
                                                                        <div className="tab-content">
                                                                            <div className="tab-pane active" id="tab21">
                                                                                <ul className="list-group">
                                                                                    <li className="list-group-item justify-content-between">
                                                                                        <b> 1st notification</b> {'=>'}    Sending date : <b>{currentReminderLine.premiere_notif_date}</b>
                                                                                        {currentReminderLine.first_notif_sent == 1 ?
                                                                                            <span className="badgetext badge bg-success rounded-pill">Sent</span>
                                                                                            :
                                                                                            <span className="badgetext badge bg-warning rounded-pill">Not yet sent</span>
                                                                                        }
                                                                                    </li>
                                                                                    <li className="list-group-item justify-content-between">
                                                                                        <b> 2nd notification</b>{'=>'}  Sending date : <b>{currentReminderLine.deuxieme_notif_date}</b>
                                                                                        {currentReminderLine.second_notif_sent == 1 ?
                                                                                            <span className="badgetext badge bg-success rounded-pill">Sent</span>
                                                                                            :
                                                                                            <span className="badgetext badge bg-warning rounded-pill">Not yet sent</span>
                                                                                        }
                                                                                    </li>

                                                                                </ul>
                                                                            </div>
                                                                            <div className="tab-pane" id="tab22">

                                                                                <div className="text-wrap" style={{ marginBottom: "25px !important" }} >

                                                                                    <Modal show={openModal} onHide={hideModal}>
                                                                                        <Modal.Header closeButton>File detail</Modal.Header>
                                                                                        <Modal.Body>
                                                                                            <DocViewer config={{
                                                                                                header: {
                                                                                                    disableHeader: true,
                                                                                                    disableFileName: true,
                                                                                                    retainURLParams: true,
                                                                                                },

                                                                                            }}
                                                                                                documents={[{ uri: `${APIURL}/api/downloadFile/${viewFileId}` }]}
                                                                                                pluginRenderers={DocViewerRenderers} />

                                                                                        </Modal.Body>

                                                                                    </Modal>

                                                                                    {loadingGetRemindeLine ? <>Loading</> :
                                                                                        currentReminderLinefiles && currentReminderLinefiles.length > 0 ?
                                                                                            currentReminderLinefiles?.map((item, index) => {


                                                                                                return < >


                                                                                                    <div className="file-image-1 " key={item.id} style={{ height: '130px' }}>

                                                                                                        <div >

                                                                                                            <img src={"/assets/images/media/" + getImageFormExtention(item.path)} className="br-5" alt="" />

                                                                                                        </div>
                                                                                                        <ul className="icons">
                                                                                                            <li><a data-bs-toggle="tooltip" data-bs-original-title="Edit" onClick={(e) => { e.preventDefault(); showModal(item.id) }} className="btn bg-success"><i className="fe fe-eye"></i></a></li>
                                                                                                            <li><a href={`${APIURL}/api/downloadFile/${item.id}`} download className="btn bg-secondary"><i className="fe fe-download"></i></a></li>
                                                                                                            <li><button onClick={(e) => { e.preventDefault(); removeFileFromFiles(item.id) }} className="btn bg-danger"><i className="fe fe-trash"></i></button></li>

                                                                                                        </ul>
                                                                                                        {/*   <span className="file-name-1" >{item.name.substring(0, 3)}...{item.name.substring(item.name.length - 8)}</span>  */}
                                                                                                        <span className="file-name-1" style={{ backgroundColor: "#ffffff" }} >{item.name}</span>




                                                                                                    </div></>
                                                                                            }) : <></>}




                                                                                </div>
                                                                                <Dropzone
                                                                                    style={{ minWidth: "505px", marginTop: "20px", marginBottom: "20px" }}
                                                                                    onChange={updateFiles}
                                                                                    value={files}
                                                                                >
                                                                                    {files.map((file) => (
                                                                                        <FileItem {...file} onDelete={removeFileFromDrag} key={file.id} info />
                                                                                    ))}
                                                                                </Dropzone>

                                                                                <button style={{ margin: "auto", maxWidth: "50%" }} className="btn btn-primary btn-block" onClick={addFileToServer} >
                                                                                    <i className="fe fe-plus me-1"></i>Add file(s)</button>
                                                                            </div>


                                                                            <div className="tab-pane" id="tab23">


                                                                                <CKEditor
                                                                                    editor={ClassicEditor}
                                                                                    data={currentReminderLine.comment || ""}
                                                                                    onReady={editor => {
                                                                                        // You can store the "editor" and use when it is needed.
                                                                                        // console.log('Editor is ready to use!', editor);
                                                                                    }}
                                                                                    onChange={(event, editor) => {
                                                                                        const data = editor.getData();
                                                                                        setCommentReminderLine(data)
                                                                                    }}

                                                                                />
                                                                                {loadingGetRemindeLine ? <Loading></Loading> :
                                                                                    <button style={{ margin: "auto", marginTop: "20px" }} className="btn btn-primary btn-block" onClick={(e) => { e.preventDefault(); addCommentReminiderLinehundler(commentReminderLine) }} >
                                                                                        <i className="fe fe-plus me-1"></i>Confirm</button>
                                                                                }
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : <></>}


                                                </div>
                                            </div>


                                            <div className="col-xl-3">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="main-profile-contact-list">
                                                            <div className="">
                                                                <div className="media mb-4 d-flex">
                                                                    <div className="media-icon bg-secondary bradius me-3 mt-1">
                                                                        <i className="fe fe-edit fs-20 text-white"></i>
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <span className="text-muted">Total patents</span>
                                                                        <div className="fw-semibold fs-25">
                                                                            {application?.researcher?.dossiers?.length}
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : <></>}
                            <div ref={ref} ></div>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default ApplicationDetails