import {
  GET_STATUTES_REQUEST,
  GET_STATUTES_SUCCESS,
  GET_STATUTES_FAILED,

  ADD_STATUS_REQUEST,
  ADD_STATUS_SUCCESS,
  ADD_STATUS_FAILED,



} from '../constants/statusConstants';
  
  export const statusReducer = (
    state = { loadingStatutes: false,statutes:[]},
    action
  ) => {
    switch (action.type) {

      // ALL Statutes
      case GET_STATUTES_REQUEST:
        return { loadingStatutes: true };

      case GET_STATUTES_SUCCESS:
        return {
          ...state,
          loadingStatutes: false,
          statutes:action.payload
        };

      case GET_STATUTES_FAILED:
        return { loadingStatutes: false, errorGetStatutes: action.payload };
    
        //ADD STATUS

      case ADD_STATUS_REQUEST:
        return { loadingAddStatus: true , ...state};

      case ADD_STATUS_SUCCESS:
        return {
          ...state,
          loadingAddStatus: false,
          statusAdded:action.payload,
          addStatus: true,
          status:state.status?.concat(action.payload)
          
        };

      case ADD_STATUS_FAILED:
        return {...state, loadingAddStatus: false, errorAddStatus: action.payload };

      default:
        return state;
    }
  };