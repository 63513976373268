import React, { useEffect, useRef, useState } from 'react'
import Footer from '../components/footer'
import LeftMenu from '../components/leftMenu'
import TopBar from '../components/topBar'
import { useDispatch, useSelector } from 'react-redux';
import { getDepartements, addDepartement, editDepartementAction, removeDepartementAction } from '../redux/actions/departementsAction';
import Loading from '../components/loading';
import Moment from 'react-moment';
import Modal from "react-bootstrap/Modal";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import 'jquery/dist/jquery.min';
import $ from 'jquery';
import { Button } from 'react-bootstrap';
import { ToastProvider, useToasts } from 'react-toast-notifications';



function Departements() {

    const { errorDepartements, addDepartementStatus, departementUpdated, editDepartementStatus } = useSelector((state) => state.departements);
    const { loadingDepartements, departements } = useSelector((state) => state.departements);
    const { token, user, role } = useSelector((state) => state.userInfo);
    const [newDepartementName, setNewDepartementName] = useState('');
    const [applicationToDelete, setApplicationToDelete] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [editDepartement, setEditDepartement] = useState({ id: "", nom: "" });
    const [openModal, setOpenModal] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const dispatch = useDispatch()
    const ref = useRef(null);
    const { addToast } = useToasts();

    useEffect(() => {


        if (departements == undefined || departementUpdated) {

            dispatch(getDepartements(token));
        }

        if (editDepartementStatus) {
            addToast("The modification has been successfully carried out", { autoDismiss: true, appearance: 'success', placement: "bottom-center" });

        }

        //initialize datatable
        $(document).ready(function () {
            setTimeout(function () {
                $('#basic-datatable').DataTable();
            }, 2000);
        });

    }
        , [dispatch, departementUpdated, departements, token])


    const addDepartementhandler = (newDepartementName) => {

        dispatch(addDepartement(token, newDepartementName));
        addToast("Entity has been added", { autoDismiss: true, appearance: 'success', placement: "bottom-center" });
        setNewDepartementName('');
        setOpenModal(false);

    }
    const removeDepartement = (departementId) => {
        setShow(false)
        // alert(departementId)
        dispatch(removeDepartementAction(token, departementId))

        addToast("Entity has been deleted", { autoDismiss: true, appearance: 'success', placement: "bottom-center" });


    };
    const editDepartementhandler = (departement) => {

        dispatch(editDepartementAction(token, departement));

        setEditDepartement({ id: "", nom: "" });
        setOpenModalEdit(false);

    }

    const showModal = () => {
        setOpenModal(true);
    };

    const hideModal = () => {
        setOpenModal(false);
    };

    const showModalEdit = (item) => {
        setEditDepartement(item)
        setOpenModalEdit(true);
    };

    const hideModalEdit = () => {
        setOpenModalEdit(false);
    };



    const { opened } = useSelector((state) => state.appConfig);
    const handleClickDelete = (id) => {
        setApplicationToDelete(id)
        setShow(true)
    }


    return (

        <div className="horizontalMenucontainer">
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={(e) => { e.preventDefault(); removeDepartement(applicationToDelete) }} >
                        Delete
                    </Button>

                </Modal.Footer>
            </Modal>
            <div className="page">
                <div className="page-main"></div>
                <TopBar />
                <LeftMenu />

                <div className="main-content app-content mt-0 " style={{ marginLeft: opened ? "270px" : "0px" }}>
                    <div className="side-app">


                        <div className="main-container container-fluid">


                            <div className="page-header">

                                <div>
                                    <ol className="breadcrumb" style={{ marginTop: '71px', marginLeft: '29px' }}>
                                        <li className="breadcrumb-item"><a href="http://localhost:3000/">Dashboard</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Entities</li>
                                    </ol>
                                </div>
                            </div>

                            {loadingDepartements ? <Loading></Loading> :
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-header d-flex justify-content-between">
                                                <h3 className="card-title" style={{ color: "#24416d", fontSize: 20, fontFamily: 'Calibri', fontWeight: 'bold' }}>Entities</h3>
                                                {role == "admin" ?
                                                    <div className="" style={{ float: 'right' }} >
                                                        <button className="modal-effect btn btn-secondary-light d-grid mb-3" data-bs-effect="effect-slide-in-right"
                                                            onClick={showModal}
                                                        > <span className="fe fe-plus">
                                                            </span> New entity</button>
                                                    </div>
                                                    : <></>
                                                }
                                                {role == "admin" ?
                                                    <Modal show={openModal} onHide={hideModal}>
                                                        <Modal.Header>Add an entity</Modal.Header>
                                                        <Modal.Body>
                                                            <form >
                                                                <div className="mb-3">
                                                                    <label for="recipient-name" className="col-form-label">Name of entity:</label>
                                                                    <input type="text"
                                                                        onChange={(e) => { setNewDepartementName(e.target.value) }}
                                                                        className={newDepartementName.length < 3 ? "form-control is-invalid" : "form-control"} id="departement_name" />
                                                                    <div style={{ display: newDepartementName.length < 4 ? " block" : "none" }} className="invalid-feedback">The entity name must consist of at least 3 letters</div>
                                                                </div>
                                                                <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); addDepartementhandler(newDepartementName) }} disabled={newDepartementName.length < 3 ? true : false}>Add</button>
                                                                <button style={{ float: "right" }} ref={ref} className="btn btn-light" data-bs-dismiss="modal" onHide={hideModal}>Cancel</button>

                                                            </form>
                                                        </Modal.Body>
                                                    </Modal>

                                                    : <></>
                                                }
                                                {role == "admin" ?

                                                    <Modal show={openModalEdit} onHide={hideModalEdit}>
                                                        <Modal.Header>Modification</Modal.Header>
                                                        <Modal.Body>
                                                            <form >
                                                                <div className="mb-3">
                                                                    <label for="recipient-name" className="col-form-label">Name of entity:</label>
                                                                    <input type="text"
                                                                        onChange={(e) => { setEditDepartement({ ...editDepartement, "nom": e.target.value }) }}
                                                                        className={editDepartement?.nom.length < 3 ? "form-control is-invalid" : "form-control"} id="departement_name_edit"
                                                                        value={editDepartement?.nom || ""} />
                                                                    <div style={{ display: editDepartement.nom.length < 4 ? " block" : "none" }} className="invalid-feedback">The entity name must necessarily contain at least 3 letters</div>
                                                                </div>
                                                                <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); editDepartementhandler(editDepartement) }} disabled={editDepartement?.nom.length < 3 ? true : false}>Confirm</button>
                                                                <button style={{ float: "right" }} ref={ref} className="btn btn-light" data-bs-dismiss="modal" onHide={hideModalEdit}>Cancel</button>

                                                            </form>
                                                        </Modal.Body>
                                                    </Modal>
                                                    : <></>
                                                }

                                            </div>
                                            {loadingDepartements ? <Loading></Loading> :
                                                <div className="card-body">

                                                    <div className="table-responsive">

                                                        <table className="table table-bordered " id="basic-datatable" style={{ borderRadius: '12px' }}>
                                                            <thead>
                                                                <tr className='ol'>
                                                                    <th>ID</th>
                                                                    <th>Name of entity</th>
                                                                    <th>Creation date</th>
                                                                    {role == "admin" ?
                                                                        <th name="bstable-actions" style={{ width: '100px' }}>Actions</th>
                                                                        : <></>
                                                                    }
                                                                </tr>

                                                            </thead>
                                                            <tbody>

                                                                {departements != undefined && departements.length > 0 ?
                                                                    departements.map((item, index) => {
                                                                        return <tr key={item.id}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{item.nom}</td>
                                                                            <td> <Moment format="YYYY/MM/DD HH:MM">{item.created_at}</Moment> </td>
                                                                            {role == "admin" ?
                                                                                <td name="bstable-actions"><div className="btn-list">
                                                                                    <button onClick={(e) => { e.preventDefault(); showModalEdit(item) }} id="bEdit" type="button" className="btn btn-sm btn-primary">
                                                                                        <span className="fe fe-edit"> </span>
                                                                                    </button>

                                                                                    <button style={{ background: "red" }} id="bEdit" onClick={(e) => { e.preventDefault(); handleClickDelete(item.id) }} type="button" className="btn btn-sm ">
                                                                                        <span><i style={{ color: "white" }} className="fa fa-trash"></i> </span>
                                                                                    </button>

                                                                                </div></td>
                                                                                : <></>
                                                                            }











                                                                        </tr>
                                                                    })
                                                                    : "0 Entity found"
                                                                }


                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                    </div>
                                </div>
                            }


                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Departements