import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import React, { useEffect, useState } from 'react'


import axios from 'axios';
import { APIURL } from '../utils';
import { color } from 'chart.js/helpers';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
import { Chart } from 'react-chartjs-2';

import { useSelector } from 'react-redux';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TreemapController,
  TreemapElement
);

const options = {
  plugins: {
    title: {
      display: true,

    },
    legend: {
      display: false,
    },
    tooltip: {
      displayColors: false,
      callbacks: {
        title(items) {
          return items[0].raw._data.name;
        },
        label(item) {
          const {
            _data: { count },
          } = item.raw;
          return [
            `Patents: ${count} `
          ];
        },
      },
    },
  },
};

// const config = {
//   type: 'treemap',
//   data: {
//     datasets: [
//       {
//         tree: data,
//         key: 'capacityMW',
//         labels: {
//           display: true,
//           formatter: (context) => context.raw._data.name,
//         },
//         backgroundColor(context) {
//           if (context.type !== 'data') return 'transparent';
//           const { dataCoverage } = context.raw._data;
//           return dataCoverage === 0
//             ? color('rgb(16,134,206)').rgbString()
//             : color('rgb(254,113,99)').alpha(dataCoverage).rgbString();
//         },
//       },
//     ],
//   },
// };

export default function Treemap() {
  const { token } = useSelector((state) => state.userInfo);


  useEffect(() => {
    const fetchData = async () => {
      // const { data } = await axios.get(`${APIURL}/api/dossiers`, { headers: { "Authorization": `Bearer ${token}` } });
      const { data } = await axios.get(`${APIURL}/api/getCountDossierByReasserchers`, { headers: { "Authorization": `Bearer ${token}` } });

      // console.log(data)
      setChartData({
        datasets: [
          {
            tree: data?.data,
            key: 'count',
            labels: {
              display: true,
              formatter: (context) => context.raw._data.full_name,
              //color:"#6c757d",
            },

            backgroundColor(context) {

              if (context.type !== 'data') return 'transparent';
              const { count } = context.raw._data;
              return count > 1
                ? color(`rgb(254,113,99)`).rgbString()
                : color(`rgba(16,134,206,0.5)`).rgbString();

            },
          },
        ],
      })
    }
    fetchData()
  }, [token]);
  const [chartData, setChartData] = useState({
    datasets: [],
  });
  return <Chart type="treemap" data={chartData} options={options} />;
}
