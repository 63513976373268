import React, { useEffect } from 'react'
import { logout } from '../redux/actions/loginAction';
import { useDispatch, useSelector } from 'react-redux';
import { changeSideBare } from '../redux/actions/configAppAction';

export default function TopBar(props) {

  const { logged } = useSelector((state) => state.userInfo);
  const dispatch = useDispatch()


  useEffect(() => {
    if (logged !== true) {
      document.location.href = '/login'
    }
  }, [logged, dispatch])


  //Login
  const logoutHandler = (event) => {
    event.preventDefault();
    dispatch(logout())

  }

  return (


    <div className="app-header header sticky">
      <div className="container-fluid main-container">
        <div className="d-flex">
          <div aria-label="Hide Sidebar" className="app-sidebar__toggle primary btn" onClick={(e) => { e.preventDefault(); dispatch(changeSideBare()); }}></div>


          {/* <Link to={{ pathname: "/" }}  >

            <div className="logo-horizontal">
              <img src="./assets/images/brand/logo.png" className="header-brand-img desktop-logo" alt="logo" />
              <img src="./assets/images/brand/logo.jpeg" className="header-brand-img light-logo1"
                alt="logo" />
            </div>
          </Link> */}


          <div className="d-flex order-lg-2 ms-auto header-right-icons">
            <div className="navbar navbar-collapse responsive-navbar p-0">
              <div className="collapse navbar-collapse" id="navbarSupportedContent-4">
                <div className="d-flex order-lg-2">
                  <div className="dropdown d-flex">
                    <div className="nav-link icon full-screen-link nav-link-bg" style={{ color: "#ff7062" }}>
                      <i className="fe fe-minimize fullscreen-button"></i>
                    </div>
                  </div>

                  <div className="dropdown d-flex profile-1">
                    <div href="" data-bs-toggle="dropdown" className="nav-link leading-none d-flex">
                      <img src="/assets/images/profile.png" alt="profile-user"
                        className="avatar  profile-user brround cover-image" />
                    </div>
                    <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow" >

                      <div className="dropdown-divider m-0"></div>


                      <div style={{ cursor: 'pointer' }} className="dropdown-item" onClick={(e) => logoutHandler(e)}>
                        <i className="dropdown-icon fe fe-alert-circle"></i> Logout
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
