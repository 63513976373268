import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import Footer from '../components/footer';
import LeftMenu from '../components/leftMenu';
import TopBar from '../components/topBar';
import { useDispatch, useSelector } from 'react-redux';
import { addChercheur, getChercheurs } from '../redux/actions/chercheursAction';
import { getDepartements } from '../redux/actions/departementsAction';
import { Button } from 'react-bootstrap';


import { getApplications, addApplication, removeDossierAction } from '../redux/actions/applicationAction';
import { getStatutes } from '../redux/actions/statusAction';
import Modal from "react-bootstrap/Modal";
import Loading from '../components/loading';

import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import 'jquery/dist/jquery.min';
import $ from 'jquery';
import { useToasts } from 'react-toast-notifications';


function Applications(props) {




    const ref = useRef(null);
    const { addToast } = useToasts();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);



    const [applicationToDelete, setApplicationToDelete] = useState();
    const [CreationStep, setCreationStep] = useState(1);
    const [openAddSection, setOpenAddSection] = useState(false);

    const { chercheurs } = useSelector((state) => state.chercheurs);
    const { departements } = useSelector((state) => state.departements);
    const { loadingApplications, addApplicationStatus, applications } = useSelector((state) => state.applications);
    const { statutes } = useSelector((state) => state.statutes);


    const { token, role } = useSelector((state) => state.userInfo);
    const [newChercheur, setNewChercheur] = useState({ nom: "", prenom: "", email: "", phone_number: "", departement_id: "" });
    const [newDossier, setNewDossier] = useState({ Numero_depot: "", ref: "", statue_id: "", project_title: "", date_de_depot: "", researcher_id: "", departement_id: "" });

    function switchTabs(step) {
        setCreationStep(step);
        // console.log(CreationStep);;
    }



    const [openModal, setOpenModal] = useState(false);
    const dispatch = useDispatch()
    useEffect(() => {
        if (addApplicationStatus) {
            addToast("The patent has been successfully created", { autoDismiss: true, appearance: 'success', placement: "bottom-center" });

        }

        if (token != undefined) {

            dispatch(getApplications(token));
            dispatch(getChercheurs(token));
            dispatch(getDepartements(token));
            dispatch(getStatutes(token));
        }


        $(document).ready(function () {
            setTimeout(function () {
                if (!$.fn.DataTable.isDataTable('#basic-datatable')) {
                    $('#basic-datatable').DataTable({
                        "pageLength": 50
                    });

                }
            }, 2000);
        });



    }
        , [dispatch, token, addApplicationStatus])


    const addChercheurHandler = (newChercheur) => {

        dispatch(addChercheur(token, newChercheur));

        setOpenModal(false);
    }

    const addDossierHandler = (newDossier) => {

        dispatch(addApplication(token, newDossier));

        setCreationStep(1)
        setOpenAddSection(false)
        setNewDossier({ Numero_depot: "", ref: "", statue_id: "", project_title: "", date_de_depot: new Date(), researcher_id: "" })
    }

    const removeDossierFromDossiers = (dossierId) => {
        setShow(false)

        dispatch(removeDossierAction(token, dossierId))

        addToast("The patent has been deleted", { autoDismiss: true, appearance: 'success', placement: "bottom-center" });

    };

    const showModal = () => {
        setOpenModal(true);
    };

    const hideModal = () => {
        setOpenModal(false);
    };
    const { opened } = useSelector((state) => state.appConfig);


    const handleClickDelete = (id) => {
        setApplicationToDelete(id)
        setShow(true)
    }


    return (
        <div className="horizontalMenucontainer">

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={(e) => { e.preventDefault(); removeDossierFromDossiers(applicationToDelete) }} >
                        Delete
                    </Button>

                </Modal.Footer>
            </Modal>


            <div id="global-loader" style={{ display: "none" }}>
                <img src="./assets/images/loader.svg" className="loader-img" alt="Loader" />
            </div>

            <div className="page">
                <div className="page-main"></div>
                <TopBar />
                <LeftMenu />

                <div className="main-content app-content mt-0" style={{ marginLeft: opened ? "270px" : "0px" }}>
                    <div className="side-app">



                        <div className="main-container container-fluid">


                            <div className="main-container container-fluid">


                                <span><i className="fe fe-file-text fs-30 " style={{ color: "#188acf" }}></i></span>    <div className="card-title">Information sur le dossier</div>
                                <div className="page-header">



                                    <div>
                                        <ol className="breadcrumb" style={{ marginLeft: '29px' }}>
                                            <li className="breadcrumb-item"><a href="http://localhost:3000/">Dashboard</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Patents</li>
                                        </ol>
                                    </div>
                                </div>

                                {role == "admin" ?
                                    <div className="row " style={{ display: openAddSection ? "block" : "none" }}>
                                        <div className="col-md-12">
                                            <div className="card" style={{ marginBlockStart: '-18px' }}>


                                                <div className="card-header d-flex justify-content-between">
                                                    <h3 className="card-title">Add a new patent</h3>

                                                    <div className="" style={{ float: 'right' }} >
                                                        <button className="modal-effect btn btn-secondary-light d-grid mb-3" data-bs-effect="effect-slide-in-right"
                                                            onClick={() => { setNewDossier({ ref: "", statue_id: "", project_title: "", date_de_depot: new Date(), researcher_id: "" }); setOpenAddSection(false) }}
                                                        ><i className="ion-close-round" data-bs-toggle="tooltip" title="" data-bs-original-title="ion-close-round" aria-label="ion-close-round"></i></button>
                                                    </div>


                                                </div>

                                                <div className="card-body">

                                                    <form id="form" onSubmit={(e) => { e.preventDefault(); addDossierHandler(newDossier) }}>
                                                        <div className="list-group">
                                                            <div className={`list-group-item ${CreationStep == 1 ? "open" : ""}`} data-acc-step="">
                                                                <div className="d-flex justify-content-between">


                                                                    <h5 className="mb-0 d-flex" data-acc-title=""><span className="acc-step-number badge rounded-pill bg-primary me-1">1</span>
                                                                        <span className="form-wizard-title">Inventor</span>

                                                                    </h5>
                                                                    <button type="button" style={{ float: 'right' }} onClick={showModal} className="btn btn-primary " ><i className="fe fe-plus me-2"></i>New inventor</button>
                                                                    <Modal show={openModal} onHide={hideModal}>
                                                                        <Modal.Header>Add a researcher</Modal.Header>
                                                                        <Modal.Body>
                                                                            <form onSubmit={(e) => { e.preventDefault(); addChercheurHandler(newChercheur) }}>
                                                                                <div className="mb-3">
                                                                                    <label for="recipient-name" className="col-form-label">Last name :</label>
                                                                                    <input type="text" className='form-control'
                                                                                        onChange={(e) => { setNewChercheur({ ...newChercheur, "nom": e.target.value }) }}
                                                                                        id="nom" required />
                                                                                </div>

                                                                                <div className="mb-3">
                                                                                    <label for="recipient-name" className="col-form-label">First name :</label>
                                                                                    <input type="text" className='form-control'
                                                                                        onChange={(e) => { setNewChercheur({ ...newChercheur, "prenom": e.target.value }) }}
                                                                                        id="prenom" required />
                                                                                </div>

                                                                                <div className="mb-3">
                                                                                    <label for="recipient-name" className="col-form-label">Email :</label>
                                                                                    <input type="text" className='form-control'
                                                                                        onChange={(e) => { setNewChercheur({ ...newChercheur, "email": e.target.value }) }}
                                                                                        id="email" required />
                                                                                </div>

                                                                                <div className="mb-3">
                                                                                    <label for="recipient-name" className="col-form-label">Phone :</label>
                                                                                    <input type="text" className='form-control'
                                                                                        onChange={(e) => { setNewChercheur({ ...newChercheur, "phone_number": e.target.value }) }}
                                                                                        id="phone_number" required />
                                                                                </div>

                                                                                <div className="mb-3">
                                                                                    <label for="recipient-name" className="col-form-label">Entity :</label>
                                                                                    <select name="country" className="form-control form-select" data-bs-placeholder="Select"
                                                                                        onChange={(e) => { setNewChercheur({ ...newChercheur, "departement_id": e.target.value }) }}
                                                                                        required       >
                                                                                        <option value="" ></option>
                                                                                        {departements != undefined && departements.length > 0 ?
                                                                                            departements.map((item, index) => {
                                                                                                return <option key={item.id} value={item.id}>{item.nom}</option>
                                                                                            })
                                                                                            : <option value="" >0 Entity found</option>
                                                                                        }

                                                                                    </select>
                                                                                </div>

                                                                                <button className="btn btn-primary" style={{ paddingLeft: '20px', marginLeft: '11px' }}>Add</button>
                                                                                <button className="btn btn-light" onClick={hideModal} style={{ marginLeft: '296px', paddingRight: '22px' }}>Cancel</button>

                                                                            </form>
                                                                        </Modal.Body>
                                                                    </Modal>

                                                                </div>

                                                                <div data-acc-content="" style={{ display: CreationStep == 1 ? 'block' : 'none' }}>
                                                                    <div className="mb-3">
                                                                        <label for="recipient-name" className="col-form-label">Inventor :</label>
                                                                        <select name="chercheur" className="form-control form-select" data-bs-placeholder="Select"
                                                                            onChange={(e) => { setNewDossier({ ...newDossier, "researcher_id": chercheurs[e.target.value].id, 'departement_id': chercheurs[e.target.value].departement_id }) }}

                                                                            required       >
                                                                            <option value="" ></option>
                                                                            {chercheurs != undefined && chercheurs.length > 0 ?
                                                                                chercheurs.map((item, index) => {
                                                                                    return <option key={item.id} value={index}>{item.nom} {item.prenom}</option>
                                                                                })
                                                                                : <option value="" >0 inventor found</option>
                                                                            }

                                                                        </select>
                                                                    </div>
                                                                    <button className="btn btn-primary float-end" onClick={(e) => { e.preventDefault(); switchTabs(2) }} >Next step</button>
                                                                </div>
                                                            </div>

                                                            <div className={`list-group-item ${CreationStep == 2 ? "open" : ""}`} data-acc-step="">
                                                                <h5 className="mb-0 d-flex" data-acc-title="">
                                                                    <span className="acc-step-number badge rounded-pill bg-primary me-1">2</span> <span className="form-wizard-title">Patent information</span></h5>
                                                                <div data-acc-content="" style={{ display: CreationStep == 2 ? 'block' : 'none' }}>
                                                                    <div className="my-3">

                                                                        <div className="form-group">
                                                                            <label>Filing number :</label>
                                                                            <input type="text" name="Numero_depot" className="form-control"
                                                                                onChange={(e) => { setNewDossier({ ...newDossier, "Numero_depot": e.target.value }) }} />
                                                                        </div>


                                                                        <div className="form-group">
                                                                            <label>Patent reference :</label>
                                                                            <input type="text" name="ref" className="form-control"
                                                                                onChange={(e) => { setNewDossier({ ...newDossier, "ref": e.target.value }) }} />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label>Patent title :</label>
                                                                            <input type="text" name="project_title" className="form-control"
                                                                                onChange={(e) => { setNewDossier({ ...newDossier, "project_title": e.target.value }) }} />
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label>Entity :</label>
                                                                            <select name="entity" className="form-control form-select" data-bs-placeholder="Select"
                                                                                onChange={(e) => { setNewDossier({ ...newDossier, "departement_id": e.target.value }) }}

                                                                                required value={newDossier.departement_id}>
                                                                                <option value=""></option>
                                                                                {departements != undefined && departements.length > 0 ?
                                                                                    departements.map((item, index) => {
                                                                                        return <option key={item.id} value={item.id}>{item.nom}</option>
                                                                                    })
                                                                                    : <option value="" >0 Entity found</option>
                                                                                }

                                                                            </select>
                                                                        </div>

                                                                        <div className="form-group">
                                                                            <label>Filing date :</label>
                                                                            <input type="date" name="date_de_depot" className="form-control"
                                                                                onChange={(e) => { setNewDossier({ ...newDossier, "date_de_depot": e.target.value }) }} />
                                                                        </div>
                                                                    </div>
                                                                    <button className="btn btn-light" onClick={(e) => { e.preventDefault(); switchTabs(1) }} >Previous step</button>
                                                                    <input type="submit" className="btn btn-primary float-end" value="Add" />

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    : <></>
                                }


                                {loadingApplications ? <Loading></Loading> :
                                    <div className="row">

                                        <div className="col-lg-12">
                                            <div className="card" style={{ marginBlockStart: '-18px' }} >
                                                <div className="card-header d-flex justify-content-between" >
                                                    <h3 className="card-title" style={{ color: "#24416d", fontSize: 20, fontFamily: 'Calibri', fontWeight: 'bold' }}>Patents</h3>
                                                    {role == "admin" ?
                                                        <div className="" style={{ float: 'right' }} >
                                                            <button className="modal-effect btn btn-secondary-light d-grid mb-3" data-bs-effect="effect-slide-in-right"
                                                                onClick={() => setOpenAddSection(true)}
                                                            > <span className="fe fe-plus">
                                                                </span> New patent </button>
                                                        </div>
                                                        : <></>
                                                    }
                                                </div>





                                                <div className="card-body">

                                                    <div className="table-responsive">

                                                        <table className="table table-bordered " id="basic-datatable" style={{ borderRadius: '12px' }} >
                                                            <thead>
                                                                <tr className='ol'>
                                                                    <th >ID</th>
                                                                    <th style={{ width: '160px' }}>filing number</th>
                                                                    <th style={{ width: "350.562px", }}>Patent title</th>
                                                                    <th >Inventor</th>
                                                                    <th style={{ width: '140px' }} >Filing date</th>
                                                                    <th >Statut</th>
                                                                    <th name="bstable-actions" style={{ width: '100px' }}>Actions</th></tr>
                                                            </thead>
                                                            <tbody>

                                                                {applications != undefined && applications?.length > 0 ?
                                                                    applications?.map((item, index) => {
                                                                        return <tr key={item.id} className="mouseHover">
                                                                            <td>{index + 1}</td>
                                                                            <td>{item.Numero_depot}</td>
                                                                            <td><Link to={{ pathname: `/patentDetails/${item.id}` }}  >{item.project_title}</Link></td>
                                                                            <td>{item.researcher.nom} {item.researcher.prenom}</td>
                                                                            <td>{item.date_de_depot || "Not yet defined"}</td>
                                                                            <td>{item.statue.name}</td>



                                                                            <td name="bstable-actions"><div className="">
                                                                                {role == "user" ?
                                                                                    <Link to={{ pathname: `/patentDetails/${item.id}` }}  >
                                                                                        <button id="bEdit" type="button" className="btn btn-sm btn-primary ">
                                                                                            <span className="fe fe-eye"> </span>Patent details
                                                                                        </button>
                                                                                    </Link>
                                                                                    : <></>
                                                                                }
                                                                                {role == "admin" ?
                                                                                    <Link to={{ pathname: `/patentDetails/${item.id}` }}  >
                                                                                        <button id="bEdit" type="button" className="btn btn-sm btn-primary ">
                                                                                            <span className="fe fe-eye"> </span>
                                                                                        </button>
                                                                                    </Link>
                                                                                    : <></>
                                                                                }
                                                                                {role == "admin" ?
                                                                                    <button style={{ background: "red" }} id="bEdit" onClick={(e) => { e.preventDefault(); handleClickDelete(item.id) }} type="button" className="btn btn-sm ">
                                                                                        <span><i style={{ color: "white" }} className="fa fa-trash"></i> </span>
                                                                                    </button>

                                                                                    : <></>
                                                                                }

                                                                            </div></td>





                                                                        </tr>
                                                                    })
                                                                    : "0 Patent found"
                                                                }


                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }


                            </div>


                        </div>

                    </div>
                </div>

                <Footer />
            </div>
        </div>
    )
}

export default Applications