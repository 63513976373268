import { BrowserRouter, Route } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import MyCustomToast from './components/MyCustomToast';
import './App.css';
import Home from './pages/home';
import Login from './pages/login';

import Application from './pages/applications';
import Departements from './pages/departements';
import Chercheurs from './pages/chercheurs';
import ApplicationDetails from './pages/applicationDetails';
import Agenda from './pages/agenda';
import { NotFound } from './pages/404';

// const USER_TYPES={
//   PUBLIC:'public User',
//   NORMAL_USER:"Normal User",
//   ADMIN_USER:"Admin User"
// }
// const CURRENT_USER_TYPE=USER_TYPES.PUBLIC
function App() {
  return (
    <ToastProvider components={{ Toast: MyCustomToast }}>
      <BrowserRouter>
        <div className="App">
          <Route path="/login" component={Login} exact></Route>
          <Route path='/' component={Home} exact />
          <Route path="/agenda" component={Agenda} exact></Route>
          <Route path="/patents" component={Application} exact></Route>
          <Route path="/entites" component={Departements} exact></Route>
          <Route path="/inventors" component={Chercheurs} exact></Route>
          <Route path="/patentDetails/:id" component={ApplicationDetails} exact></Route>
          <Route path="*" component={NotFound} exact></Route>
        </div>
      </BrowserRouter>
    </ToastProvider>
  );

}


export default App;
