import React, { useEffect } from 'react'

import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';




export default function LeftMenu(props) {


    const { opened } = useSelector((state) => state.appConfig);
    const location = useLocation();

    const active = location.pathname;

    useEffect(() => {



    }, [active])



    return (

        <div className="sticky">
            <div className="app-sidebar__overlay" data-bs-toggle="sidebar"></div>
            <div className="app-sidebar">
                <div className="app-sidebar ps ps--active-y" style={{ width: opened ? "270px" : "0px" }}>
                    <div className="side-header">
                        <Link to={{ pathname: "/" }}>
                            <div className="header-brand1" >
                                <img src="../assets/images/brand/logo.svg" className="header-brand-img desktop-logo" alt="logo" />
                                <img src="../assets/images/brand/logo-1.svg" className="header-brand-img toggle-logo"
                                    alt="logo" />
                                <img src="../assets/images/brand/logo-2.svg" className="header-brand-img light-logo" alt="logo" />
                                <img src="../assets/images/brand/logo.png" className="header-brand-img light-logo1"
                                    alt="logo" />
                            </div>
                        </Link>

                    </div>
                    <div className="main-sidemenu">
                        <div className="slide-left disabled" id="slide-left"><svg xmlns="http://www.w3.org/2000/svg"
                            fill="#7b8191" width="24" height="24" viewBox="0 0 24 24">
                            <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
                        </svg></div>
                        <ul className="side-menu" style={{ paddingTop: "20px" }}>
                            <li className="sub-category">
                                <h3>SERVICES</h3>
                            </li>
                            <li className="slide">

                                <Link to={{ pathname: "/" }}>
                                    <div style={active === "/" ? { "color": "#1086ce" } : { "color": '#24416d' }}
                                        className="side-menu__item" data-bs-toggle="slide" ><i
                                            className="side-menu__icon fe fe-home"></i><span
                                                className="side-menu__label" >Dashboard </span></div>

                                </Link>
                                <Link to={{ pathname: "/agenda" }}>
                                    <div style={active === "/agenda" ? { "color": "#1086ce" } : { "color": '#24416d' }}
                                        className="side-menu__item" data-bs-toggle="slide" ><i
                                            className="side-menu__icon fe fe-calendar"></i>
                                        <span
                                            className="side-menu__label" >Agenda </span></div>

                                </Link>

                                <Link to={{ pathname: "/patents" }}>

                                    <div style={active === "/patents" ? { "color": "#1086ce" } : { "color": '#24416d' }}
                                        className="side-menu__item has-link" data-bs-toggle="slide" ><i
                                            className="side-menu__icon fe fe-layers"></i><span
                                                className="side-menu__label">Patents</span></div>
                                </Link>

                                <Link to={{ pathname: "/inventors" }}
                                >
                                    <div style={active === "/inventors" ? { "color": "#1086ce" } : { "color": '#24416d' }}
                                        className="side-menu__item has-link" data-bs-toggle="slide" ><i
                                            className="side-menu__icon fe fe-users"></i><span
                                                className="side-menu__label">Inventors</span></div>
                                </Link>

                                <Link to={{ pathname: "/entites" }}  >
                                    <div style={active === "/entites" ? { "color": "#1086ce" } : { "color": '#24416d' }}
                                        className="side-menu__item has-link" data-bs-toggle="slide" ><i
                                            className="side-menu__icon icon icon-organization"></i><span
                                                className="side-menu__label">Entities</span></div>
                                </Link>
                            </li>

                        </ul>
                        <div className="slide-right" id="slide-right"><svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191"
                            width="24" height="24" viewBox="0 0 24 24">
                            <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
                        </svg></div>
                    </div>
                </div>

            </div>
        </div>

    )
}
