import Axios from 'axios';
import {APIURL} from '../../utils'


import {
    GET_CHERCHEURS_REQUEST,
    GET_CHERCHEURS_SUCCESS,
    GET_CHERCHEURS_FAILED,

    ADD_CHERCHEUR_REQUEST,
    ADD_CHERCHEUR_SUCCESS,
    ADD_CHERCHEUR_FAILED,

    DELETE_CHERCHEUR_REQUEST,
    DELETE_CHERCHEUR_SUCCESS,
    DELETE_CHERCHEUR_FAILED,
    

   EDIT_CHERCHEUR_REQUEST,
   EDIT_CHERCHEUR_SUCCESS,
   EDIT_CHERCHEUR_FAILED,
} from '../constants/chercheursConstants';

export const getChercheurs = (token) => async (dispatch) => {
      dispatch({
        type: GET_CHERCHEURS_REQUEST,
      });
      try {
        
        const {data} = await Axios.get(`${APIURL}/api/researchers`,{ headers: {"Authorization" : `Bearer ${token}`} });
        dispatch({ type: GET_CHERCHEURS_SUCCESS, payload: data });

    
      } catch (error) {
       
        dispatch({ type: GET_CHERCHEURS_FAILED, payload:error });

      }
    };

    export  const  removeChercheurAction = (token, chercheurId) => async (dispatch) => {

  
      dispatch({
        type: DELETE_CHERCHEUR_REQUEST,
      });
      try {
    
        const { data } = await Axios.delete(`${APIURL}/api/deleteR/${chercheurId}`, 
        { headers: { "Authorization": `Bearer ${token}`, 
        'Content-Type': 'multipart/form-data' } });
        dispatch({ type: DELETE_CHERCHEUR_SUCCESS, payload: data });
    
      } catch (error) {
        dispatch({ type: DELETE_CHERCHEUR_FAILED, payload:error });
    
      }
    };
    

export const addChercheur= (token,chercheur) => async (dispatch) => {

      dispatch({
        type: ADD_CHERCHEUR_REQUEST,
      });
      try {
        
        const {data} = await Axios.post(`${APIURL}/api/researchers`,chercheur,{ headers: {"Authorization" : `Bearer ${token}`} });
        
        dispatch({ type: ADD_CHERCHEUR_SUCCESS, payload: data });
    
      } catch (error) {

        console.log(error)
       
        dispatch({ type: ADD_CHERCHEUR_FAILED, payload:error });
      

      }
    };


    export const editChercheurAction= (token,chercheur) => async (dispatch) => {

      dispatch({
        type: EDIT_CHERCHEUR_REQUEST,
      });
      try {
        
        const {data} = await Axios.put(`${APIURL}/api/researchers/${chercheur.id}`,chercheur,{ headers: {"Authorization" : `Bearer ${token}`} });
        
        dispatch({ type: EDIT_CHERCHEUR_SUCCESS, payload: data });
    
      } catch (error) {

        console.log(error)
       
       dispatch({ type: EDIT_CHERCHEUR_FAILED, payload:error });
      

      }
    };