import {
  GET_DEPARTEMENTS_REQUEST,
  GET_DEPARTEMENTS_SUCCESS,
  GET_DEPARTEMENTS_FAILED,

  ADD_DEPARTEMENT_REQUEST,
  ADD_DEPARTEMENT_SUCCESS,
  ADD_DEPARTEMENT_FAILED,

  DELETE_DEPARTEMENT_REQUEST,
  DELETE_DEPARTEMENT_SUCCESS,
  DELETE_DEPARTEMENT_FAILED,



  EDIT_DEPARTEMENT_REQUEST,
  EDIT_DEPARTEMENT_SUCCESS,
  EDIT_DEPARTEMENT_FAILED,
} from '../constants/departementsConstants';

export const departementReducer = (
  state = { loadingDepartements: false},
  action
) => {
  switch (action.type) {

    // ALL Departements
    case GET_DEPARTEMENTS_REQUEST:
      return { loadingDepartements: true };

    case GET_DEPARTEMENTS_SUCCESS:
      return {
        ...state,
        loadingDepartements: false,
        departements: action.payload
      };

    case GET_DEPARTEMENTS_FAILED:
      return { loadingDepartements: false, errorDepartements: action.payload };

    //ADD DEP

    case ADD_DEPARTEMENT_REQUEST:
      return { loadingAddDepartement: true, ...state };

    case ADD_DEPARTEMENT_SUCCESS:
      return {
        ...state,
        loadingAddDepartement: false,
        departementAdded: action.payload,
        addDepartementStatus: true,
        departements: state.departements?.concat(action.payload)

      };

    case ADD_DEPARTEMENT_FAILED:
      return { ...state, loadingAddDepartement: false, errorAddDepartement: action.payload };


    //EDIT DEP



    case DELETE_DEPARTEMENT_REQUEST:
      return { loadingDeleteDepartement: true , ...state};

    case DELETE_DEPARTEMENT_SUCCESS:
      // console.log(action.payload)
      return {
        ...state,
        loadingDeleteDepartement: false,
        departements:state.departements?.filter((d) => d.id !== action.payload.deletedDepartement.id)

      }
   
      ;
  
    case DELETE_DEPARTEMENT_FAILED:
      // console.log(action.payload)
      return {...state, loadingDeleteDepartement: false, errorDeleteDepartement: action.payload };





    case EDIT_DEPARTEMENT_REQUEST:
      return { loadingEditDepartement: true, ...state,editDepartementStatus: false };

    case EDIT_DEPARTEMENT_SUCCESS:
      return {
        ...state,
        loadingEditDepartement: false,
        departementUpdated: action.payload,
        editDepartementStatus: true,

      };

    case EDIT_DEPARTEMENT_FAILED:
      return { ...state, loadingEditDepartement: false,editDepartementStatus: false, errorEditDepartement: action.payload };

    default:
      return state;
  }
};