import React, { useEffect, useState } from 'react'


import axios from 'axios';
import { APIURL } from '../utils';


import { useSelector } from 'react-redux';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export function Barrs() {
  const { token } = useSelector((state) => state.userInfo);
  useEffect(() => {
    const fetchData = async () => {
      // const { data } = await axios.get(`${APIURL}/api/dossiers`, { headers: { "Authorization": `Bearer ${token}` } });
      const { data } = await axios.get(`${APIURL}/api/getCountReserByDepart`, { headers: { "Authorization": `Bearer ${token}` } });

      // console.log(data)
      setChartData({

        labels: data?.data.map((item) => item.nom),
        datasets: [
          {
            label: 'Inventors',
            data: data?.data.map((item) => item.count),
            backgroundColor: [
              'rgb(254,113,99)',
              'rgb(16,134,206)',
              'rgb(36,65,109)',
              'rgb(254,113,99)',
              'rgb(16,134,206)',
              'rgb(36,65,109)'

            ],


            borderColor: [
              'rgba(254,113,99,0.3)',
              'rgba(16,134,206,0.3)',
              'rgba(36,65,109,0.3)',
              'rgba(254,113,99,0.3)',
              'rgba(16,134,206,0.3)',
              'rgba(36,65,109,0.3)'

            ],
            borderWidth: 1,
          },

        ],

      })




    }
    fetchData()
  }, [token]);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },

      title: {
        display: true,

      },
    },
  };
  const [chartData, setChartData] = useState({
    datasets: [],
  });

  return <Bar options={options} data={chartData} />;
}
export default Barrs