
import React, { useEffect, useState } from 'react'


import axios from 'axios';
import { APIURL } from '../utils';


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { useSelector } from 'react-redux';

export function PieChart() {
  const { token } = useSelector((state) => state.userInfo);



  useEffect(() => {
    const fetchData = async () => {
      // const { data } = await axios.get(`${APIURL}/api/dossiers`, { headers: { "Authorization": `Bearer ${token}` } });
      const { data } = await axios.get(`${APIURL}/api/getCountStatuesByDossiers`, { headers: { "Authorization": `Bearer ${token}` } });

      // console.log(data)
      setChartData({

        labels: data?.data.map((item) => item.name),
        datasets: [
          {
            label: 'Patents',
            data: data?.data.map((item) => item.count),
            backgroundColor: [
              'rgb(254,113,99)',
              'rgb(16,134,206)',
              'rgb(36,65,109)',
              'rgb(254,113,99)',
              'rgb(16,134,206)',
              'rgb(36,65,109)'

            ],


            borderColor: [
              'rgba(254,113,99,0.3)',
              'rgba(16,134,206,0.3)',
              'rgba(36,65,109,0.3)',
              'rgba(254,113,99,0.3)',
              'rgba(16,134,206,0.3)',
              'rgba(36,65,109,0.3)'

            ],
            borderWidth: 1,
          },


        ],

      })




    }
    fetchData()
  }, [token]);
  const [chartData, setChartData] = useState({
    datasets: [],
  });
  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        title: { display: true, text: "Numero depot" },
      },
    },
  };



  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  return <Bar options={options} data={chartData} />;
}








export default PieChart
